import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import parseISO from 'date-fns/parseISO';
import Comments from '../comments/Comments';
import axios from '../../../../axios';
import DialogWithButton from '../../../DialogWithButton';

import { format } from '../../../../format';

import { consultationQuestions } from './questions';
import DownloadPatientSerie from '../../../DownloadPatientSerie';

class Consultation extends Component {
  refuseCheckUp = async () => {
    const { fetchPatient } = this.props;

    const { patient } = this.props;

    await axios.get(`/patient/${patient.id}/refuse-check-up`);

    await fetchPatient();
  };

  render() {
    const { patient } = this.props;

    return (
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12}>
          {!patient.check_up_decision_practician_at ? (
            <DialogWithButton
              title="Déclarer non éligible"
              description="Déclarer le patient non éligible durant la consultation?"
              buttonText="Refuser"
              onAccept={this.refuseCheckUp}
              fullWidth
              color="secondary"
            />
          ) : (
            <Typography align="center">
              Patient déclaré{' '}
              {patient.check_up_decision_practician
                ? 'éligible'
                : 'non éligible'}{' '}
              le{' '}
              {format(
                parseISO(patient.check_up_decision_practician_at),
                'dd/MM/yyyy - HH:mm'
              )}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DownloadPatientSerie
            patientId={patient.id}
            serie="check_up"
            text="Télécharger photos RDV"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Comments type="patient" id={patient.id} readOnly />
        </Grid>
        {!!patient.questions && (
          <Grid item xs={12}>
            {consultationQuestions.map((question) => (
              <Grid container spacing={2} key={question.name}>
                <Grid item xs={12} sm={6}>
                  <Typography>{question.fr}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {patient.questions[question.name] && (
                    <Typography
                      color={
                        patient.questions[question.name] ===
                        question.expectedAnswer
                          ? 'initial'
                          : 'error'
                      }
                    >
                      {patient.questions[question.name] === 'yes'
                        ? 'Oui'
                        : 'Non'}
                      {question.name === 'premolars' &&
                        patient.questions.premolars_details &&
                        ` (${patient.questions.premolars_details})`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default Consultation;
