/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import parseISO from 'date-fns/parseISO';
import Dialog from '../../../Dialog';

import { format } from '../../../../format';
import axios from '../../../../axios';

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
});

class Confirmation extends Component {
  state = {
    decision: false,
    open: false
  };

  handleDecision = async () => {
    const { patient, actor, fetchPatient } = this.props;
    const { decision } = this.state;

    const action = decision ? 'accept' : 'refuse';

    await axios.get(`patient/${patient.id}/${action}-treatment-${actor}`);

    fetchPatient();

    this.setState({ open: false });
  };

  handleAccept = () => {
    this.setState({ decision: true, open: true });
  };

  handleRefuse = () => {
    this.setState({ decision: false, open: true });
  };

  render() {
    const { patient, actor, classes } = this.props;

    const actors = {
      supplier: 'fournisseur',
      practician: 'praticien',
      patient: 'patient'
    };
    const { open, decision: stateDecision } = this.state;

    const actorText = actors[actor];

    const decision = patient[`decision_${actor}`];
    const date = patient[`decision_${actor}_at`];

    const confirm = (
      <Dialog
        open={open}
        title={`Confirmation du plan de traitement pour le ${actorText}`}
        description={`Êtes-vous certain de vouloir ${
          stateDecision ? 'accepter' : 'refuser'
        } le traitement pour le ${actorText}`}
        onCancel={() => this.setState({ open: false })}
        onAccept={this.handleDecision}
      />
    );

    const buttons = (
      <Grid item sm={4}>
        <Button
          size="small"
          variant="contained"
          component="span"
          color="secondary"
          onClick={this.handleAccept}
          className={classes.button}
        >
          Accepter
        </Button>
        <Button
          size="small"
          variant="contained"
          component="span"
          color="secondary"
          onClick={this.handleRefuse}
          className={classes.button}
        >
          Refuser
        </Button>
        {confirm}
      </Grid>
    );

    const noDecision = (
      <>
        {buttons}
        <Grid item sm={4}>
          <Typography>Le {actorText} n'a pas encore décidé</Typography>
        </Grid>
      </>
    );

    if (!date) {
      return noDecision;
    }

    return (
      <>
        {buttons}
        <Grid item sm={4}>
          <Typography color="textSecondary">Décision du {actorText}</Typography>
          <Typography>
            {decision ? 'Accepté' : 'Refusé'} le{' '}
            {format(parseISO(date), 'dd/MM/yyyy - HH:mm')}
          </Typography>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Confirmation);
