import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { Form, Field } from 'react-final-form';
import Select from '../../../final-form/Select';
import DatePicker from '../../../final-form/DatePicker';
import Checkbox from '../../../final-form/Checkbox';
import TextField from '../../../final-form/TextField';

import Loadable from '../../../Loadable';

import axios, { baseV2 } from '../../../../axios';
import { format } from '../../../../format';

const styles = (theme) => ({
  chip: {
    margin: theme.spacing(2),
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white'
    }
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  button: {
    marginRight: theme.spacing()
  },
  loading: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      marginRight: theme.spacing(2)
    }
  }
});
class GetBooking extends Component {
  state = {
    open: false,
    practician: null,
    slots: [],
    availabilities: [],
    selectedDate: null,
    selectedHour: null,
    fetching: false,
    availabilitiesFetching: false
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  handleError = (errorMessage) => {
    const { snackbar } = this.props;

    if (snackbar) {
      snackbar.open(errorMessage, 'error');
    } else {
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    }
  };

  handleSubmit = async ({ hour, date, practician_id, type, is_free }) => {
    const { patient, snackbar, onSuccess } = this.props;

    const formattedDate = format(new Date(date), 'yyyy-MM-dd');

    try {
      await axios.post(`/patient/${patient.id}/add-booking`, {
        practician_id,
        date: formattedDate,
        hour,
        type,
        is_free: is_free ? 1 : 0
      });

      if (onSuccess) {
        onSuccess();
      }
      this.resetAll();

      this.setOpen(false);

      if (snackbar) {
        snackbar.open('Le rendez-vous a bien été pris en compte.', 'success');
      }
    } catch (error) {
      if (error.response.data.code === 'practician_not_available') {
        this.handleError(
          "Le rendez n'a pas pu être pris. Le practicien n'est pas disponible."
        );

        this.fetchPracticians();
      }
    }
  };

  handleOpen = () => {
    this.fetchPracticians();
    this.setOpen(true);
  };

  fetchAvailabilitiesForPractician = async (practicianId) => {
    const { availabilities } = this.state;

    try {
      this.setState({ availabilitiesFetching: true });

      const { data } = await baseV2.get(
        `practicians/${practicianId}/availabilities`
      );

      const newAvailabilities = availabilities.map((item) => {
        if (item.practician.id === practicianId) {
          return { ...item, slots: data };
        }
        return item;
      });

      this.setState({ availabilities: newAvailabilities });
    } catch (error) {
      this.handleError('Failed to fetch availabilities for practician');
    } finally {
      this.setState({ availabilitiesFetching: false });
    }
  };

  fetchPracticians = async () => {
    const { practician } = this.state;

    try {
      this.setState({ fetching: true });

      const { data } = await axios.get('/practicians');
      const availabilities = data.map((item) => ({
        practician: item,
        slots: null
      }));

      this.setState({ availabilities });

      if (practician) {
        this.handleChangePractician(practician.id);
      }
    } catch (error) {
      this.handleError('Failed to fetch list of practicians');
    } finally {
      this.setState({ fetching: false });
    }
  };

  handleChangePractician = async (practicianId) => {
    this.setState({ practician: null, slots: [] });

    await this.fetchAvailabilitiesForPractician(practicianId);

    const { availabilities } = this.state;

    const index = availabilities.findIndex(
      (a) => a.practician.id === practicianId
    );

    this.resetHour();

    this.setState({
      practician: availabilities[index].practician,
      slots: availabilities[index].slots
    });
  };

  handleSelectHour = (date, hour) => {
    this.setState({ selectedDate: date, selectedHour: hour });
  };

  resetAll = () => {
    this.setState({ practician: null, slots: [] }, () => {
      this.resetHour();
    });
  };

  resetHour = () => {
    this.setState({ selectedDate: null, selectedHour: null });
  };

  validate = (values) => {
    const errors = {};

    if (!values.practician_id) {
      errors.practician_id = 'Obligatoire';
    }
    if (!values.date) {
      errors.date = 'Obligatoire';
    }
    if (!values.hour) {
      errors.hour = 'Obligatoire';
    }
    if (!values.type) {
      errors.type = 'Obligatoire';
    }

    return errors;
  };

  render() {
    const {
      open,
      practician,
      availabilities,
      slots,
      selectedDate,
      selectedHour,
      fetching,
      availabilitiesFetching
    } = this.state;
    const { classes, type } = this.props;

    return (
      <>
        <Button
          className={classes.button}
          onClick={this.handleOpen}
          variant="contained"
          color="primary"
        >
          Prendre RDV
        </Button>

        <Dialog
          open={open}
          onClose={() => {
            this.setOpen(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Prise de RDV</DialogTitle>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={{
              type
            }}
            validate={this.validate}
            render={({ handleSubmit, form, values, invalid }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Loadable
                    loading={fetching}
                    message="Chargement des praticiens..."
                  >
                    {Object.keys(availabilities).length ? (
                      <>
                        <DialogContentText>
                          Veuillez choisir un praticien et un horaire pour le
                          rendez-vous
                        </DialogContentText>

                        <Field
                          formControlProps={{
                            fullWidth: true
                          }}
                          name="type"
                          label="Type de rendez-vous"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          render={(props) => <Select {...props} />}
                        >
                          <MenuItem value="pre_treatment">
                            Début de traitement
                          </MenuItem>
                          <MenuItem value="mid_treatment">
                            Mi-traitement
                          </MenuItem>
                          <MenuItem value="end_treatment">
                            Fin de traitement
                          </MenuItem>
                          <MenuItem value="urgent">Urgence</MenuItem>
                          <MenuItem value="other">Autre</MenuItem>
                        </Field>

                        <Field
                          formControlProps={{
                            fullWidth: true
                          }}
                          name="practician_id"
                          label="Selectionner un praticien"
                          onChange={(e) => {
                            this.handleChangePractician(e.target.value);
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          render={(props) => <Select {...props} />}
                        >
                          {availabilities.map((availability) => (
                            <MenuItem
                              key={availability.practician.id}
                              value={availability.practician.id}
                            >{`${availability.practician.id} - Dr. ${availability.practician.user.fullname} | ${availability.practician.alias}`}</MenuItem>
                          ))}
                        </Field>
                        <Field
                          name="is_free"
                          type="checkbox"
                          label="Gratuit"
                          component={Checkbox}
                        />

                        <Field
                          name="manual"
                          type="checkbox"
                          label="Saisie manuelle"
                          component={Checkbox}
                        />

                        {practician && (
                          <>
                            <Typography color="textSecondary" gutterBottom>
                              Adresse
                            </Typography>
                            <Typography>
                              {practician.user.addresses[0].address}
                            </Typography>
                            <Typography gutterBottom>
                              {practician.user.addresses[0].city}{' '}
                              {practician.user.addresses[0].postal_code}
                            </Typography>{' '}
                            {!Object.keys(slots).length && (
                              <Typography color="error">
                                Aucune disponibilité pour ce praticien
                              </Typography>
                            )}
                            {values.manual && (
                              <>
                                <Field
                                  name="date"
                                  label="Date"
                                  render={(props) => (
                                    <DatePicker
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...props}
                                      clearable
                                      format="dd/MM/yyyy"
                                      fullWidth
                                    />
                                  )}
                                />
                                <Field
                                  name="hour"
                                  label="Heure"
                                  component={TextField}
                                  type="time"
                                  fullWidth
                                />
                              </>
                            )}
                            {!values.manual &&
                              slots.map((slot, index) => {
                                const formatted = format(
                                  new Date(slot.date),
                                  'dd MMMM yyyy'
                                );

                                return (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <Fragment key={index}>
                                    <Typography
                                      color="textSecondary"
                                      gutterBottom
                                    >
                                      {formatted}
                                    </Typography>
                                    {slot.slots.map((hour, slotIndex) => (
                                      <Chip
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={slotIndex}
                                        label={hour}
                                        onClick={() => {
                                          this.handleSelectHour(
                                            slot.date,
                                            hour
                                          );
                                          form.change('date', slot.date);
                                          form.change('hour', hour);
                                        }}
                                        className={classnames(classes.chip, {
                                          [classes.active]:
                                            selectedDate === slot.date &&
                                            selectedHour === hour
                                        })}
                                      />
                                    ))}
                                  </Fragment>
                                );
                              })}
                          </>
                        )}
                      </>
                    ) : (
                      <Typography>Aucun praticien disponible</Typography>
                    )}

                    {availabilitiesFetching && (
                      <div className={classes.loading}>
                        <Typography>Fetching availabilities</Typography>
                        <CircularProgress size={20} color="secondary" />
                      </div>
                    )}
                  </Loadable>
                </DialogContent>
                <DialogActions>
                  {!availabilities.length && (
                    <Button onClick={this.fetchPracticians} color="primary">
                      Recharger
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      this.setOpen(false);
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={invalid} color="primary">
                    Prendre RDV
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </Dialog>
      </>
    );
  }
}

GetBooking.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default withStyles(styles)(GetBooking);
