import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SnackbarConsumer } from '../Snackbar';

import List from './list/ListPatients';
import View from './ViewPatient';

import ListExport from './list/exportToSupplier/ListExport';

const Patients = () => (
  <SnackbarConsumer>
    {(snackbar) => (
      <Switch>
        <Route exact path="/patients" component={List} />
        <Route exact path="/patients/export" component={ListExport} />
        <Route
          path="/patient/:id/:tab?/:piece?"
          // eslint-disable-next-line react/jsx-props-no-spreading
          render={(props) => <View {...props} snackbar={snackbar} />}
        />
      </Switch>
    )}
  </SnackbarConsumer>
);

export default Patients;
