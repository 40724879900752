import React from 'react';

import { Grid, Button } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import TextField from '../../../final-form/TextField';

const AddComment = ({ onSubmit }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="comment"
                label="Commentaire"
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default AddComment;
