import client from 'axios';

const axios = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin`,
  withCredentials: true
});

export const v2 = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/admin`,
  withCredentials: true
});

export const baseV2 = client.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2`,
  withCredentials: true
});

/**
 * Used for S3 request to avoid cors issues
 */
export const axiosWithoutCredentials = client.create();

export default axios;
