import React, { Fragment } from 'react';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  avatar: {
    width: 110,
    height: 110,
    marginBottom: theme.spacing(2)
  },
  left: {
    textAlign: 'left'
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  }
});

const Informations = ({ infos, practician, addresses, classes }) => (
  <Grid container>
    <Grid item sm={4}>
      <Avatar className={classes.avatar} alt={infos.fullname}>
        <Typography variant="h3">
          {infos.lastname.charAt(0)}
          {infos.firstname.charAt(0)}
        </Typography>
      </Avatar>
      <Typography variant="h5">{`Dr. ${infos.fullname}`}</Typography>
      {practician.alias && (
        <Typography variant="subtitle1">{practician.alias}</Typography>
      )}
      <Typography variant="subtitle1" gutterBottom>
        {practician.formatted_type}
      </Typography>
    </Grid>
    <Grid item sm={4}>
      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Informations
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Reference : </span>
        {practician.reference}
      </Typography>
      {practician.name && (
        <Typography className={classes.left}>
          <span className={classes.label}>Nom du cabinet : </span>
          {practician.name}
        </Typography>
      )}
      <Typography className={classes.left}>
        <span className={classes.label}>RPPS : </span> {practician.adeli}
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Statut : </span> {practician.status}
      </Typography>
      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Rendez-vous
      </Typography>
      <Typography className={classes.left} gutterBottom>
        <span className={classes.label}>Durée : </span>{' '}
        {practician.duration_booking} minutes
      </Typography>
      <Typography className={classes.left} gutterBottom>
        <span className={classes.label}>Source disponibilités : </span>{' '}
        {practician.availabilities_source}
      </Typography>
      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Tarifs
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Prix consultation : </span>{' '}
        {practician.price_booking} €
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Prix traitement : </span>{' '}
        {practician.price_treatment} €
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Credit : </span>{' '}
        {practician.credit / 100} €
      </Typography>
    </Grid>
    <Grid item sm={4}>
      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Contact
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Email : </span>
        <a target="_blank" href={`mailto:${infos.email}`} rel="noreferrer">
          {infos.email}
        </a>
      </Typography>
      <Typography className={classes.left} gutterBottom={!infos.mobile}>
        <span className={classes.label}>Fixe : </span>
        <a target="_blank" href={`tel:${practician.landline}`} rel="noreferrer">
          {practician.landline}
        </a>
      </Typography>
      {infos.mobile ? (
        <Typography className={classes.left} gutterBottom>
          <span className={classes.label}>Mobile : </span>
          <a target="_blank" href={`tel:${infos.mobile}`} rel="noreferrer">
            {infos.mobile}
          </a>
        </Typography>
      ) : null}

      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Adresse
      </Typography>
      {addresses &&
        addresses.map((address) => (
          <Fragment key={address.id}>
            <Typography className={classes.left}>{address.address}</Typography>
            <Typography className={classes.left}>
              {address.city} {address.postal_code}
            </Typography>
            <Typography gutterBottom className={classes.left}>
              {address.country}
            </Typography>
            <Typography className={classes.left}>{address.address3}</Typography>
          </Fragment>
        ))}
    </Grid>
  </Grid>
);

export default withStyles(styles)(Informations);
