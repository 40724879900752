import React from 'react';
import TextField from '@material-ui/core/TextField';

const MyTextField = ({
  input: { name, onChange, value, onBlur, onFocus },
  meta,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (event) => {
    onChange(event);
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      name={name}
      value={value}
      onChange={handleChange}
      margin="normal"
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export default MyTextField;
