import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Link } from 'react-router-dom';

import { parseISO } from 'date-fns';
import Loadable from '../../Loadable';
import axios from '../../../axios';
import { format } from '../../../format';

class Bookings extends Component {
  state = {
    bookings: [],
    isFetching: false
  };

  componentDidMount = () => {
    this.fetchBookings();
  };

  fetchBookings = async () => {
    const { practician } = this.props;
    const { data: bookings } = await axios.get(
      `practician/${practician.id}/bookings`
    );

    this.setState({ bookings });
  };

  render() {
    const { isFetching, bookings } = this.state;
    return (
      <Loadable loading={isFetching}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Heure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.length ? (
              bookings.map((b) => {
                return (
                  <TableRow key={b.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/patient/${b.patient.id}`}>
                        {b.patient.user.fullname}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {format(parseISO(b.date), 'dd MMMM yyyy')}
                    </TableCell>
                    <TableCell>{format(parseISO(b.date), 'HH:mm')}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  Aucune consultation à venir
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Loadable>
    );
  }
}

export default Bookings;
