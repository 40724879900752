/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import moment from 'moment';
import 'moment/locale/fr';

import { withStyles } from '@material-ui/core/styles';
import RRule from 'rrule';
import { green, grey } from '@material-ui/core/colors';
import { addMinutes, differenceInMinutes, parseISO } from 'date-fns';
import axios, { baseV2 } from '../../../axios';
import { occurenceIsExcluded } from './helper';
import AddAvailability from './AddAvailability';
import { ShowBooking } from './ShowBooking';
import { DeleteAvailability } from './DeleteAvailability';
import { DeleteRecurrentAvailability } from './DeleteRecurrentAvailability';

moment.locale('fr');
const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const styles = (theme) => ({
  root: {
    height: '100%'
  },
  calendarContainer: {
    padding: theme.spacing(2),
    height: '1000px'
  },
  availability: {
    background: theme.palette.primary.main
  },
  recurrentAvailability: {
    background: green[600]
  },
  excludedRecurrentAvailability: {
    background: grey[600]
  },
  booking: {
    background: theme.palette.secondary.main
  }
});

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const Agenda = ({ classes, snackbar, practician }) => {
  const [availabilities, setAvailabilities] = useState([]);
  const [recurrentAvailabilities, setRecurrentAvailabilities] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [toAdd, setToAdd] = useState(null);
  const [showAvailabilities, setShowAvailabilities] = useState(true);
  const [showBookings, setShowBookings] = useState(true);
  const [showRecurrentAvailability, setShowRecurrentAvailability] =
    useState(true);

  const fetchAvailabilities = async () => {
    try {
      const { data: fetchedAvailabilities } = await axios.get(
        `practician/${practician.id}/availabilities`
      );
      setAvailabilities(fetchedAvailabilities);
      // eslint-disable-next-line no-empty
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchRecurrentAvailabilities = async () => {
    try {
      const { data: fetchedRecurrentAvailabilities } = await baseV2.get(
        'recurrent-availabilities',
        {
          params: {
            practician_id: practician.id
          }
        }
      );
      setRecurrentAvailabilities(fetchedRecurrentAvailabilities);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchBookings = async () => {
    try {
      const { data: fetchedBookings } = await axios.get(
        `practician/${practician.id}/bookings`
      );

      setBookings(fetchedBookings);
      // eslint-disable-next-line no-empty
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const fetchEvents = useCallback(
    async (notification = true) => {
      await Promise.all([
        fetchAvailabilities(),
        fetchBookings(),
        fetchRecurrentAvailabilities()
      ]);

      if (notification) {
        snackbar.open('Agenda à jour', 'success');
      }
    },
    [snackbar]
  );

  useEffect(() => {
    fetchEvents(false);
  }, []);

  const getEvents = () => {
    let recurrentAvailabilitiesEvents = [];

    if (showRecurrentAvailability && recurrentAvailabilities.length) {
      recurrentAvailabilities.map((ra) => {
        const [startHours, startMinutes] = ra.start.split(':');
        const [endHours, endMinutes] = ra.end.split(':');

        const rule = new RRule({
          freq: RRule.WEEKLY,
          dtstart: new Date(ra.begins_at),
          until: new Date(ra.expires_at),
          interval: 1
        });

        // Injecting recurrent dates into calendar
        // eslint-disable-next-line no-restricted-syntax
        for (const date of rule.all()) {
          const startDate = new Date(date);
          startDate.setHours(startHours);
          startDate.setMinutes(startMinutes);

          const endDate = new Date(date);
          endDate.setHours(endHours);
          endDate.setMinutes(endMinutes);

          recurrentAvailabilitiesEvents = [
            ...recurrentAvailabilitiesEvents,
            {
              id: ra.id,
              start: startDate,
              end: endDate,
              title: occurenceIsExcluded(ra, date)
                ? "N'est pas disponible"
                : 'Disponible',
              draggable: false,
              type: 'recurrent-availability',
              data: ra
            }
          ];
        }
        return recurrentAvailabilitiesEvents;
      });
    }

    const availabilitiesEvents =
      (showAvailabilities &&
        availabilities &&
        availabilities.map((a) => ({
          id: a.id,
          start: parseISO(a.start),
          end: parseISO(a.end),
          title: 'Disponible',
          draggable: false,
          type: 'availability'
        }))) ||
      [];

    const bookingsEvents =
      (showBookings &&
        bookings.length &&
        bookings.map((b) => ({
          id: b.id,
          start: parseISO(b.date),
          end: addMinutes(parseISO(b.date), b.practician.duration_booking),
          title: b.patient.user.fullname,
          draggable: true,
          type: 'booking'
        }))) ||
      [];

    return [
      ...bookingsEvents,
      ...availabilitiesEvents,
      ...recurrentAvailabilitiesEvents
    ];
  };

  const create = (event) => {
    let { end } = event;
    const { duration_booking } = practician;
    const duration = differenceInMinutes(event.end, event.start);

    if (duration < duration_booking) {
      end = addMinutes(event.start, duration_booking);
    }

    setToAdd({ ...event, end });
  };

  const handleSelect = (eventToSelect) => {
    setSelectedEvent(eventToSelect);
  };

  const handleAddAvailability = async (isRecurrent = false) => {
    if (isRecurrent) {
      await fetchRecurrentAvailabilities();
    } else {
      await fetchAvailabilities();
    }
    setToAdd(null);
  };

  const onDeleteAvailability = async () => {
    fetchAvailabilities();
    setSelectedEvent(null);
  };

  const onDeleteRecurrentAvailability = async () => {
    fetchRecurrentAvailabilities();
    setSelectedEvent(null);
  };

  const checkboxes = (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={showAvailabilities}
            onChange={() => setShowAvailabilities(!showAvailabilities)}
            color="primary"
          />
        }
        label="Disponibilités"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showBookings}
            onChange={() => setShowBookings(!showBookings)}
          />
        }
        label="Consultations"
      />
      <FormControlLabel
        control={
          <GreenCheckbox
            checked={showRecurrentAvailability}
            onChange={() => setShowRecurrentAvailability((show) => !show)}
          />
        }
        label="Disponibilités récurrentes"
      />
    </FormGroup>
  );

  const getEventClassName = (event) => {
    switch (event.type) {
      case 'booking':
        return classes.booking;
      case 'availability':
        return classes.availability;
      case 'recurrent-availability':
        if (occurenceIsExcluded(event.data, event.start)) {
          return classes.excludedRecurrentAvailability;
        }
        return classes.recurrentAvailability;
      default:
        return {};
    }
  };

  return (
    <div className={classes.calendarContainer}>
      <Button onClick={fetchEvents}>Recharger</Button>
      {checkboxes}
      <DragAndDropCalendar
        localizer={localizer}
        resizable
        selectable
        events={getEvents()}
        // views={["agenda", "week", 'day']}
        drilldownView="week"
        min={new Date(2018, 1, 1, 7, 0, 0)}
        max={new Date(2018, 1, 1, 20, 0, 0)}
        onSelectSlot={create}
        defaultView={Views.WEEK}
        onSelectEvent={handleSelect}
        step={15}
        eventPropGetter={(e) => ({
          className: getEventClassName(e)
        })}
      />

      {selectedEvent &&
        // eslint-disable-next-line no-nested-ternary
        (selectedEvent.type === 'booking' ? (
          <ShowBooking
            event={selectedEvent}
            onClose={() => setSelectedEvent(null)}
          />
        ) : selectedEvent.type === 'availability' ? (
          <DeleteAvailability
            practician={practician}
            event={selectedEvent}
            snackbar={snackbar}
            onDelete={onDeleteAvailability}
            onCancel={() => setSelectedEvent(false)}
          />
        ) : (
          <DeleteRecurrentAvailability
            event={selectedEvent}
            snackbar={snackbar}
            onDelete={onDeleteRecurrentAvailability}
            onCancel={() => setSelectedEvent(false)}
          />
        ))}

      {toAdd && (
        <AddAvailability
          practician={practician}
          onAdd={handleAddAvailability}
          onError={() => setToAdd(null)}
          onCancel={() => setToAdd(null)}
          event={toAdd}
          snackbar={snackbar}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(Agenda);
