import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LocalHospital from '@material-ui/icons/LocalHospital';
import CalendarToday from '@material-ui/icons/CalendarToday';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Dashboard from '@material-ui/icons/Dashboard';
import Settings from '@material-ui/icons/Settings';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: theme.sidebarWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  }
});

const Category = ({ icon, primary, url }) => (
  <ListItem button component={Link} to={url}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={primary} />
  </ListItem>
);

const SideBar = ({ classes, open, handleDrawerClose }) => (
  <Drawer
    variant="permanent"
    classes={{
      paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose)
    }}
    open={open}
  >
    <div className={classes.toolbarIcon}>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
    <Divider />
    <List>
      <Category icon={<Dashboard />} primary="Dashboard" url="/" />
      <Category icon={<AccountCircle />} primary="Patients" url="/patients" />
      <Category
        icon={<LocalHospital />}
        primary="Praticiens"
        url="/practicians"
      />
      <Category icon={<CalendarToday />} primary="RDV" url="/bookings" />
      <Category
        icon={<EventAvailableIcon />}
        primary="Availabilities"
        url="/availabilities"
      />
    </List>
    <Divider />
    <List>
      <Category icon={<Settings />} primary="Settings" url="/settings" />
      <Category
        icon={<ImportExportIcon />}
        primary="Export"
        url="/patients/export"
      />
      <ListItem
        button
        href={`${process.env.REACT_APP_API_URL}/nova`}
        component="a"
        target="_blank"
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Nova" />
      </ListItem>
    </List>
  </Drawer>
);

export default withStyles(styles, { withTheme: true })(SideBar);
