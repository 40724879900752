import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const status = {
  pending: 'en attente',
  confirmed: 'confirmé',
  cancelled: 'annulé'
};

const Status = ({ booking }) => (
  <Grid item sm={4}>
    <Typography color="textSecondary">Statut</Typography>
    <Typography>Ce rendez est {status[booking.status]}</Typography>

    {booking.status === 'cancelled' ? (
      <Typography>Penser à rembourser le client</Typography>
    ) : null}
  </Grid>
);

export default Status;
