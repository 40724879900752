import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import axios from '../../axios';
import Header from '../Header';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  header: {
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 700
  },
  headline: {
    marginBottom: theme.spacing(3)
  }
});

class PracticiansList extends Component {
  state = {
    practicians: [],
    error: null
  };

  async componentDidMount() {
    this.fetchPatients();
  }

  fetchPatients = async () => {
    const response = await axios.get('/practicians');
    this.setState({ practicians: response.data });
  };

  render() {
    const { classes } = this.props;
    const { practicians } = this.state;

    return (
      <>
        <Header breadcrumbs={[{ name: 'Praticiens', url: '/practicians' }]} />

        <Paper className={classes.root}>
          <div className={classes.header}>
            <Fab
              color="primary"
              aria-label="add"
              size="small"
              component={Link}
              to="/practician/create"
            >
              <AddIcon />
            </Fab>
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Reference</TableCell>
                <TableCell>Practicien</TableCell>
                <TableCell>Tarifs</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {practicians.length ? (
                practicians.map((p, index) => {
                  const { user: infos } = p;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Link to={`/practician/${p.id}`}>{p.reference}</Link>
                      </TableCell>
                      <TableCell>{infos.fullname}</TableCell>
                      <TableCell>
                        {p.price_booking}€ <br /> {p.price_treatment}€
                      </TableCell>
                      <TableCell>
                        {p.type === 'orthodontist'
                          ? 'Orthodontiste'
                          : 'Dentiste'}{' '}
                        <br /> {p.adeli}
                      </TableCell>
                      <TableCell>
                        {infos.email} <br /> {p.landline}
                      </TableCell>
                      <TableCell>
                        <Typography
                          color={p.status !== 'active' ? 'error' : 'initial'}
                        >
                          {p.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row">
                    Aucun practicien
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(PracticiansList);
