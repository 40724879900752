/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mime from 'mime-types';

import Tooltip from '@material-ui/core/Tooltip';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import { STLViewer } from 'react-stl-obj-viewer';
import { withStyles } from '@material-ui/core/styles';
import { Fab } from '@material-ui/core';
import { isMimeTypeImage, getFileContent } from '../utils/files';

import Loadable from './Loadable';

const styles = (theme) => ({
  thumbnailContainer: {
    width: '100%',
    height: 150,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  thumbnail: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  button: {
    margin: theme.spacing()
  },
  iconContainer: {
    fontSize: '120px',
    color: 'red'
  }
});

class Download extends Component {
  state = {
    fileContent: null
  };

  componentDidMount = async () => {
    const { file } = this.props;

    // no need to fetch content if image
    if (isMimeTypeImage(file.mime_type)) {
      return;
    }

    const fileContent = await getFileContent(file.url);
    this.setState({ fileContent });
  };

  getFileName = () => {
    const { file, fileName } = this.props;
    const extension = mime.extension(file.mime_type);

    if (!fileName) {
      return `${file.filename}.${extension}`;
    }

    return `${fileName}.${extension}`;
  };

  getStlRenderFile = () => {
    const { fileContent } = this.state;
    const { file } = this.props;

    return new File([fileContent], this.getFileName(), {
      type: file.mime_type
    });
  };

  render() {
    const { file, classes, description, fileName, isFetching, actionButtons } =
      this.props;

    const { fileContent } = this.state;

    if (!file || Object.keys(file).length === 0) {
      return null;
    }

    const renderPreview = () => {
      if (isMimeTypeImage(file.mime_type)) {
        return (
          <a href={file.url} target="_blank" rel="noopener noreferrer">
            <img
              className={classes.thumbnail}
              src={file.url}
              alt={description || fileName}
            />
          </a>
        );
      }

      if (file.mime_type === 'model/stl') {
        if (!fileContent) {
          return null;
        }

        return (
          <div className={classes.iconContainer}>
            <STLViewer
              file={this.getStlRenderFile()}
              modelColor="#FFFFF"
              backgroundColor="#FAACA8"
            />
          </div>
        );
      }

      return (
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          <div className={classes.iconContainer}>
            <InsertPhotoIcon fontSize="inherit" className={classes.thumbnail} />
          </div>
        </a>
      );
    };

    return (
      <div className={classes.root}>
        <div className={classes.thumbnailContainer}>
          <Loadable loading={isFetching}>{renderPreview()}</Loadable>
        </div>
        <Tooltip title="Télécharger" placement="bottom">
          <Fab
            className={classes.button}
            color="secondary"
            aria-label="add"
            size="small"
            href={file.url}
            target="_blank"
            rel="noopener"
          >
            <SaveAltIcon />
          </Fab>
        </Tooltip>
        {actionButtons}
      </div>
    );
  }
}

Download.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object,
  fileName: PropTypes.string,
  description: PropTypes.string.isRequired
};

export default withStyles(styles)(Download);
