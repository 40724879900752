import React, { Component, Fragment } from 'react';

import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Script from 'react-load-script';

import { baseV2 } from '../axios';
import { TagsContext } from '../store/Tags';
import { SnackbarProvider } from './Snackbar';

import TreatmentPlan from './patients/treatment-plan/Main';

import SideBar from './SideBar';
import AppBar from './AppBar';
import Content from './Content';

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto'
  }
});

const DRAWER_COOKIE = 'drawer-state';

class Authenticated extends Component {
  state = {
    open: false,
    googleMapsLoaded: false,
    tags: []
  };

  handleDrawerOpen = () => {
    this.setDrawerState(true);
  };

  handleDrawerClose = () => {
    this.setDrawerState(false);
  };

  setDrawerState = (open) => {
    this.setState({ open });
    localStorage.setItem(DRAWER_COOKIE, open ? 'open' : 'close');
  };

  getDrawerState = () => {
    const open = localStorage.getItem(DRAWER_COOKIE) === 'open';

    this.setDrawerState(open);
  };

  fetchTags = async () => {
    try {
      const { data: newTags } = await baseV2.get('/tags');
      this.setState({ tags: newTags });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  componentDidMount = () => {
    this.getDrawerState();
    this.fetchTags();
  };

  render() {
    const { classes, logout } = this.props;
    const { googleMapsLoaded, open, tags } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Switch>
          <Route
            path="/patient/:id/treatment-plan/:id_treatment_plan"
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <TreatmentPlan {...props} />}
          />
          <Route
            render={() => (
              <>
                <AppBar
                  drawerOpen={open}
                  handleDrawerOpen={this.handleDrawerOpen}
                  logout={logout}
                />
                <SideBar
                  open={open}
                  handleDrawerClose={this.handleDrawerClose}
                />

                <main className={classes.content}>
                  <div className={classes.appBarSpacer} />

                  <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&libraries=places`}
                    onLoad={() => {
                      this.setState({ googleMapsLoaded: true });
                    }}
                  />
                  <SnackbarProvider>
                    <TagsContext.Provider value={tags}>
                      {googleMapsLoaded && <Content />}
                    </TagsContext.Provider>
                  </SnackbarProvider>
                </main>
              </>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Authenticated);
