import React from 'react';

import Typography from '@material-ui/core/Typography';

import Breadcrumbs from './Breadcrumbs';

const Header = ({ breadcrumbs, title }) => (
  <div>
    <Typography variant="h5">{title}</Typography>

    {breadcrumbs ? <Breadcrumbs steps={breadcrumbs} /> : null}
  </div>
);

export default Header;
