import React from 'react';
import { Field, Form } from 'react-final-form';
import { addMonths, endOfDay, isBefore, parseISO, startOfDay } from 'date-fns';
import format from 'date-fns/format';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Dialog from '../../Dialog';
import MyCheckbox from '../../final-form/Checkbox';
import MyTextField from '../../final-form/TextField';
import axios, { baseV2 } from '../../../axios';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

const AddAvailability = ({
  practician,
  classes,
  snackbar,
  event,
  onAdd,
  onError,
  onCancel
}) => {
  const addAvailability = async () => {
    const start = format(event.start, 'yyyy-MM-dd HH:mm');
    const end = format(event.end, 'yyyy-MM-dd HH:mm');

    try {
      await axios.post(`practician/${practician.id}/availability`, {
        start,
        end
      });

      await onAdd();
      snackbar.open('La disponibilité a bien été ajoutée', 'success');
    } catch (error) {
      snackbar.open("La disponibilité n'a pas pu être ajoutée", 'error');
      onError();
    }
  };

  const addRecurrentAvailability = async (values) => {
    // Adding time(end of the day) to expiration date
    const begins_at = format(
      startOfDay(new Date(event.start)),
      'yyyy-MM-dd HH:mm:ss'
    );
    const expires_at = format(
      endOfDay(new Date(values.expires_at)),
      'yyyy-MM-dd HH:mm:ss'
    );
    const start = format(event.start, 'HH:mm');
    const end = format(event.end, 'HH:mm');
    const day_of_week = event.start.getDay();

    try {
      baseV2.post('recurrent-availabilities', {
        practician_id: practician.id,
        begins_at,
        expires_at,
        start,
        end,
        day_of_week,
        excluded_dates: []
      });

      snackbar.open(
        'La disponibilité récurrente a bien été ajoutée',
        'success'
      );
      await onAdd(true);
    } catch (error) {
      snackbar.open("La disponibilité n'a pas pu être enregistrée", 'error');
      onError();
    }
  };
  const handleCreatedRecurrentAvailability = (values) => {
    if (values.recurrent) {
      return addRecurrentAvailability(values);
    }
    return addAvailability(values);
  };

  const validate = (values) => {
    const errors = {};
    if (values.recurrent) {
      if (!values.expires_at) {
        errors.expires_at = 'Obligatoire';
      } else if (isBefore(parseISO(values.expires_at), new Date())) {
        errors.expires_at = 'Selectionnez une date postérieure';
      }
    }
    return errors;
  };

  if (!event) return null;

  return (
    <Form
      onSubmit={handleCreatedRecurrentAvailability}
      validate={validate}
      initialValues={{
        expires_at: format(addMonths(event.start, 1), 'yyyy-MM-dd')
      }}
      render={({ handleSubmit, values }) => (
        <Dialog
          open
          title="Ajouter une disponibilité"
          description={`Voulez-vous ajouter une disponibilité du ${moment(
            event.start
          ).format('DD/MM HH:mm')} au ${moment(event.end).format(
            'DD/MM HH:mm'
          )}`}
          cancelText="Annuler"
          confirmText="Ajouter"
          onCancel={onCancel}
          onAccept={handleSubmit}
        >
          <form
            onSubmit={handleSubmit}
            className={classes.container}
            id="recurrent-availability"
          >
            <Grid container>
              <Grid item xs={12}>
                <Field
                  type="checkbox"
                  name="recurrent"
                  label="Récurrente"
                  component={MyCheckbox}
                />
              </Grid>
              {values.recurrent && (
                <Grid item xs={12}>
                  <Field
                    id="expires_at"
                    name="expires_at"
                    label="Expire le"
                    component={MyTextField}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Dialog>
      )}
    />
  );
};

export default withStyles(styles)(AddAvailability);
