import React from 'react';
import Button from '@material-ui/core/Button';
import axios from '../axios';

const DownloadPatientSerie = ({
  patientId,
  serie,
  text = 'Download serie',
  ...buttonProps
}) => {
  const handleDownload = async () => {
    const {
      data: { url }
    } = await axios.get(`/patient/${patientId}/archive-serie`, {
      params: {
        serie
      }
    });

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleDownload}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      {text}
    </Button>
  );
};

export default DownloadPatientSerie;
