/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class Case extends Component {
  state = {
    open: false
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  validate = (values) => {
    const errors = {};

    if (!values.reference_supplier) {
      errors.reference_supplier = 'Obligatoire';
    }

    return errors;
  };

  render() {
    const { patient } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item sm={4}>
          {patient.reference_supplier ? (
            <>
              <Typography color="textSecondary" gutterBottom>
                Numéro dossier fournisseur
              </Typography>
              <Typography gutterBottom>{patient.reference_supplier}</Typography>
            </>
          ) : (
            <Typography color="textSecondary" gutterBottom>
              Vous n'avez pas encore crée le dossier fournisseur pour ce client
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default Case;
