import React from 'react';
import LoadingPage from './LoadingPage';

const RedirectToAuth = () => {
  const encodedURI = encodeURI(window.location.href);

  window.location.replace(
    `${process.env.REACT_APP_AUTH_URL}/login?redirect=${encodedURI}`
  );

  return <LoadingPage />;
};

export default RedirectToAuth;
