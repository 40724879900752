import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Link } from 'react-router-dom';

import Loadable from '../../Loadable';
import axios from '../../../axios';

class Patients extends Component {
  state = {
    patients: [],
    isFetching: false
  };

  componentDidMount = () => {
    this.fetchBookings();
  };

  fetchBookings = async () => {
    const { practician } = this.props;
    const { data: patients } = await axios.get(
      `practician/${practician.id}/patients`
    );

    this.setState({ patients });
  };

  render() {
    const { isFetching, patients } = this.state;
    return (
      <Loadable loading={isFetching}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>Durée du traitement</TableCell>
              <TableCell>Progression</TableCell>
              <TableCell>Prix</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.length ? (
              patients.map((p) => {
                return (
                  <TableRow key={p.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/patient/${p.id}`}>{p.user.fullname}</Link>
                    </TableCell>
                    <TableCell>
                      {p.treatment_duration && `${p.treatment_duration} mois`}{' '}
                    </TableCell>
                    <TableCell>
                      {p.treatment_started_at
                        ? `${p.actual_aligners.serie} / ${p.nb_aligners} (${p.progression_aligners}%)`
                        : ''}
                    </TableCell>
                    <TableCell>{p.formatted_price}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell component="th" scope="row">
                  Aucun patient
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Loadable>
    );
  }
}

export default Patients;
