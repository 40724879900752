/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import parseISO from 'date-fns/parseISO';
import { format } from '../../../../format';

const ChooseDate = ({ patient }) => {
  const booking = patient.bookings.find((b) => b.type === 'pre_treatment');

  // const cancelBooking = async () => {
  // 	try {
  // 		await axios.post(`patient/${patient.id}/cancel-booking`, {
  // 			reason: ""
  // 		});

  // 		await fetchPatient();
  // 		snackbar.open("Le rendez-vous a bien été annulé", "success");
  // 	} catch (error) {
  // 		snackbar.open("Le rendez-vous n'a pas pu être annulé", "error");
  // 	}
  // };

  if (!booking) {
    return null;
  }

  return (
    <>
      {booking && booking.date && booking.practician ? (
        <>
          <Grid item sm={3}>
            <Typography color="textSecondary" gutterBottom>
              Praticien
            </Typography>

            <>
              <Typography>Dr. {booking.practician.user.fullname}</Typography>
              <Typography>
                {booking.practician.user.addresses[0].address}
              </Typography>
              <Typography gutterBottom>
                {booking.practician.user.addresses[0].city}{' '}
                {booking.practician.user.addresses[0].postal_code}
              </Typography>
            </>
          </Grid>
          <Grid item sm={3}>
            <Typography color="textSecondary" gutterBottom>
              Date
            </Typography>
            <>
              <Typography>
                {format(parseISO(booking.date), 'dd MMMM yyyy à HH:mm')}
              </Typography>
            </>
          </Grid>
          <Grid item sm={3}>
            <Typography color="textSecondary" gutterBottom>
              Statut
            </Typography>
            <>
              <Typography>
                {
                  [
                    { name: 'pending', display: 'En attente' },
                    { name: 'confirmed', display: 'Confirmé' },
                    { name: 'canceled', display: 'Annulé' },
                    { name: 'done', display: 'Terminé' }
                  ].find((t) => t.name === booking.status).display
                }
              </Typography>
            </>
          </Grid>
        </>
      ) : (
        <Grid item sm={3}>
          <Typography>Ce patient n'a pas de RDV</Typography>
        </Grid>
      )}
    </>
  );
};

export default ChooseDate;
