/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const Confirmation = () => {
  return (
    <Grid item sm={4}>
      <Typography color="textSecondary">Décision du fournisseur</Typography>
      <Typography color="textSecondary">Removed logic</Typography>
    </Grid>
  );
};

export default Confirmation;
