import React from 'react';
import { Route } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Header from '../../Header';
import FeedsList from './Feeds';
import Pieces from './Pieces';

import { SnackbarConsumer } from '../../Snackbar';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  }
});

function Feeds({ classes, match }) {
  return (
    <div className="dashboard">
      <Header title="Feeds" />

      <Paper className={classes.root}>
        <SnackbarConsumer>
          {(snackbar) => (
            <Grid container>
              <Grid item sm={4}>
                <Typography variant="h6">Feeds</Typography>

                <FeedsList snackbar={snackbar} />
              </Grid>
              <Grid item sm={8}>
                <Typography variant="h6">Pieces</Typography>

                <Route
                  path={`${match.url}/:feedId`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  render={(props) => <Pieces {...props} snackbar={snackbar} />}
                />
              </Grid>
            </Grid>
          )}
        </SnackbarConsumer>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(Feeds);
