import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  }
});

const MySelect = ({
  input: { name, value, onChange, ...restInput },
  meta,
  label,
  formControlProps,
  classes,
  onChange: onChangeField,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formControlProps}
      className={classes.formControl}
      error={showError}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        name={name}
        onChange={(e) => {
          onChange(e);
          if (onChangeField) {
            onChangeField(e);
          }
        }}
        inputProps={restInput}
        value={value}
        autoWidth
      />

      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(MySelect);
