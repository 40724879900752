import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import { parse, parseISO } from 'date-fns';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { format } from '../../format';
import Header from '../Header';
import Form from './FormPractician';

import axios from '../../axios';

const styles = (theme) => ({
  wrapper: {
    padding: theme.spacing(2)
  }
});

class Edit extends Component {
  state = {
    practician: null,
    updated: false,
    fetched: false
  };

  fetchPractician = async () => {
    const { match } = this.props;

    const { data: practician } = await axios.get(
      `/practician/${match.params.id}`
    );

    this.setState({ practician });
  };

  componentDidMount = async () => {
    await this.fetchPractician();
  };

  handleSubmit = async (values) => {
    const { snackbar } = this.props;
    try {
      const response = await this.updatePractician(values);

      if (response.status === 200) {
        snackbar.open('Le practicien a bien été modifié', 'success');
        // triggers redirection
        this.setState({ updated: true });
      }
      return undefined;
    } catch ({ response: { data } }) {
      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  updatePractician = async (values) => {
    const { birthday, password, ...rest } = values;
    const { match } = this.props;

    const sensitive = {};

    if (password !== '') {
      sensitive.password = password;
    }

    return axios.put(`/practician/${match.params.id}`, {
      birthday: format(parseISO(birthday), 'yyyy-MM-dd'),
      ...rest,
      ...sensitive
    });
  };

  render = () => {
    const { classes, match } = this.props;
    const { practician, updated } = this.state;

    if (updated) {
      return <Redirect to={`/practician/${match.params.id}`} />;
    }

    if (!practician) {
      return null;
    }

    const { user: infos } = practician;

    return (
      <>
        <Header
          breadcrumbs={[
            { name: 'Praticiens', url: '/practicians' },
            {
              name: `Dr. ${infos.fullname}`,
              url: `/practician/${match.params.id}`
            },
            {
              name: 'Modifier',
              url: `/practician/${match.params.id}/edit`
            }
          ]}
        />

        <Paper className={classes.wrapper}>
          <Form
            initialValues={{
              firstname: infos.firstname,
              lastname: infos.lastname,
              gender: infos.gender,
              birthday: parse(infos.birthday, 'yyyy-MM-dd', new Date()),
              address: infos.address.address,
              address3: infos.address.address3,
              city: infos.address.city,
              postal_code: infos.address.postal_code,
              latitude: infos.address.latitude,
              longitude: infos.address.longitude,
              email: infos.email,
              mobile: infos.mobile,
              alias: practician.alias,
              landline: practician.landline,
              adeli: practician.adeli,
              type: practician.type,
              price_booking: practician.price_booking,
              price_treatment: practician.price_treatment,
              name: practician.name,
              duration_booking: practician.duration_booking,
              availabilities_source: practician.availabilities_source
            }}
            onSubmit={this.handleSubmit}
            submitText="Enregistrer"
          />
        </Paper>
      </>
    );
  };
}

export default withStyles(styles)(Edit);
