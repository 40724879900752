/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import BlockIcon from '@material-ui/icons/Block';

import { withStyles } from '@material-ui/core/styles';
import axios from '../axios';

import Download from './Download';

const styles = (theme) => ({
  notFoundContainer: {
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.default
  }
});

class FetchFile extends Component {
  state = {
    file: null,
    isFetching: false
  };

  componentDidMount = () => {
    this.fetchFile();
  };

  fetchFile = async () => {
    const {
      receiverId,
      receiverType,
      resourceId,
      resourceType,
      type,
      meta,
      last,
      onSuccess
    } = this.props;

    this.setState({ isFetching: true });

    const params = {
      receiver_type: receiverType,
      receiver_id: receiverId,
      type,
      meta: JSON.stringify(meta),
      last: last ? JSON.stringify(true) : false
    };

    if (resourceId) {
      params.resource_id = resourceId;
    }

    if (resourceType) {
      params.resource_type = resourceType;
    }

    const { data: file } = await axios.get('files', {
      params
    });

    this.setState({ isFetching: false });

    if (!Object.keys(file).length) {
      return;
    }

    this.setState({ file }, () => {
      if (onSuccess) {
        onSuccess(file);
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    const { refetch } = this.props;
    if (refetch !== prevProps.refetch) {
      this.fetchFile();
    }
  };

  render() {
    const { file, isFetching } = this.state;
    const {
      fileName,
      showNotFound,
      notFoundMessage,
      description,
      classes,
      hidden,
      actionButtons
    } = this.props;

    if ((!file && !showNotFound) || hidden) {
      return null;
    }

    if (!file && showNotFound) {
      return (
        <div className={classes.notFoundContainer}>
          <BlockIcon />
          <Typography>{notFoundMessage || 'Aucun fichier'}</Typography>
        </div>
      );
    }

    return (
      <Download
        isFetching={isFetching}
        file={file}
        fileName={fileName}
        description={description}
        actionButtons={actionButtons}
      />
    );
  }
}

FetchFile.propTypes = {
  receiverId: PropTypes.number.isRequired,
  receiverType: PropTypes.oneOf(['patient', 'practician', 'econsult'])
    .isRequired,
  resourceId: PropTypes.number,
  resourceType: PropTypes.oneOf(['treatment_plan']),
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  showNotFound: PropTypes.bool,
  notFoundMessage: PropTypes.string,
  hidden: PropTypes.bool,
  description: PropTypes.string.isRequired
};

export default withStyles(styles)(FetchFile);
