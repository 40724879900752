import { Box, Typography } from '@material-ui/core';
import React from 'react';

const ExportInformation = ({ information }) => {
  return (
    <Box p={2}>
      <Typography>
        <Typography component="span" variant="subtitle2">
          {'Case ID : '}
        </Typography>
        <a
          href={`https://kline-portal.com/#/kline_case/detail/${information.case.data.ID}`}
          target="_blank"
          rel="noreferrer"
        >
          {information.case.data.ID}
        </a>
      </Typography>
      <Typography>
        <Typography component="span" variant="subtitle2">
          {'X-ray : '}
        </Typography>
        {information.files.xray ? 'Yes' : 'No'}
      </Typography>
      <Typography>
        <Typography component="span" variant="subtitle2">
          {'Photos : '}
        </Typography>
        {information.files.photos.length}
      </Typography>
      <Typography>
        <Typography component="span" variant="subtitle2">
          {'Scans : '}
        </Typography>
        {information.files.scans.length}
      </Typography>
      <Box mt={2}>
        <Typography variant="subtitle2">Metadata</Typography>
        {Object.entries(information.case.data.CASE).map(([key, value]) => {
          return (
            <Typography key={key}>
              <Typography component="span" variant="subtitle2">
                {`${key} : `}
              </Typography>
              {value}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default ExportInformation;
