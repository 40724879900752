import React, { Component, Fragment } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Script from 'react-load-script';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';
import CheckoutForm from './CheckoutForm';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    // minWidth: '300px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center'
  }
});

class App extends Component {
  state = {
    scriptLoaded: false
  };

  render() {
    const { patient, product, classes, alreadyPaid, onSuccess, price } =
      this.props;
    const { scriptLoaded } = this.state;
    const stripe = (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC}>
        <Paper className={classes.root}>
          <Typography variant="h5">Paiement</Typography>
          <Typography variant="subtitle1">{price}€</Typography>
          <Elements>
            <CheckoutForm
              patient={patient}
              product={product}
              alreadyPaid={alreadyPaid}
              onSuccess={onSuccess}
              price={price}
            />
          </Elements>
        </Paper>
      </StripeProvider>
    );

    return (
      <>
        <Script
          url="https://js.stripe.com/v3/"
          onLoad={() => {
            this.setState({ scriptLoaded: true });
          }}
        />

        {scriptLoaded ? stripe : null}
      </>
    );
  }
}

export default withStyles(styles)(App);
