import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BookingIcon from '@material-ui/icons/Schedule';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
});

class PracticianTabs extends Component {
  change = (value) => {
    const { changeTab } = this.props;
    changeTab(value);
  };

  render() {
    const { classes, url, value } = this.props;
    return (
      <Tabs
        value={value}
        indicatorColor="secondary"
        textColor="secondary"
        className={classes.root}
      >
        <Tab
          icon={<BookingIcon />}
          onClick={() => this.change('agenda')}
          component={Link}
          to={`${url}/agenda`}
          value="agenda"
          label="Agenda"
        />
        <Tab
          icon={<FavoriteIcon />}
          onClick={() => this.change('bookings')}
          component={Link}
          to={`${url}/bookings`}
          value="bookings"
          label="Consultations"
        />
        <Tab
          icon={<FavoriteIcon />}
          onClick={() => this.change('patients')}
          component={Link}
          to={`${url}/patients`}
          value="patients"
          label="Patients"
        />
        <Tab
          icon={<AttachMoneyIcon />}
          onClick={() => this.change('charges')}
          component={Link}
          to={`${url}/charges`}
          value="charges"
          label="Facturation"
        />
      </Tabs>
    );
  }
}

export default withStyles(styles)(PracticianTabs);
