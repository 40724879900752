/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import Informations from './Informations';
import Aligners from './Aligners';
import Aligner from './Aligner';

import axios from '../../../../axios';
import DialogWithButton from '../../../DialogWithButton';

const styles = () => ({
  root: {
    textAlign: 'left'
  }
});

class Treatment extends Component {
  state = {
    remainingAligners: []
  };

  componentDidMount = () => {
    this.fetchRemainingAligners();
  };

  startTreatment = async () => {
    const { patient, fetchPatient } = this.props;

    await axios.get(`patient/${patient.id}/start-treatment`);

    this.fetchRemainingAligners();
    fetchPatient();
  };

  fetchRemainingAligners = async () => {
    const { patient } = this.props;

    const response = await axios.get(
      `patient/${patient.id}/get-remaining-aligners`
    );

    this.setState({ remainingAligners: response.data });
  };

  handleNextAligners = async () => {
    const { patient, fetchPatient } = this.props;

    await axios.get(`patient/${patient.id}/next-aligners`);

    this.fetchRemainingAligners();
    fetchPatient();
  };

  render() {
    const { classes, patient } = this.props;

    const { remainingAligners } = this.state;

    let content = null;

    if (!patient.treatment_started_at) {
      if (!patient.nb_aligners) {
        content = (
          <Typography>Veuillez renseigner le nombre d'aligneurs</Typography>
        );
      } else {
        content = (
          <DialogWithButton
            buttonText="Commencer le traitement"
            title="Commencer le traitement"
            description="Êtes-vous certains de vouloir commener le traitement?"
            onAccept={this.startTreatment}
          />
        );
      }
    } else {
      content = (
        <Grid container className={classes.root}>
          <DialogWithButton
            buttonText="Prochains aligneurs"
            title="Prochains aligneurs"
            description="Êtes-vous certains de vouloir passer aux prochains aligneurs?"
            onAccept={this.handleNextAligners}
          />
          <Informations
            patient={patient}
            remainingAligners={remainingAligners}
          />

          <Switch>
            <Route
              path="/patient/:id_patient/treatment/:serie"
              // eslint-disable-next-line react/jsx-props-no-spreading
              render={(props) => <Aligner {...props} patient={patient} />}
            />
            <Route
              render={(props) => (
                <Aligners
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  patient={patient}
                  remainingAligners={remainingAligners}
                />
              )}
            />
          </Switch>
        </Grid>
      );
    }

    return content;
  }
}

export default withStyles(styles)(Treatment);
