/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import parseISO from 'date-fns/parseISO';
import axios from '../../../axios';
import { format } from '../../../format';

import InteractiveRender from './TreatmentPlanInteractiveRenderer';
import StaticRender from './TreatmentPlanStaticRenderer';
import Upload from './TreatmentPlanUpload';

import Comments from '../view/comments/Comments';

class Main extends Component {
  state = {
    treatmentPlan: null
  };

  componentDidMount() {
    this.fetchTreatmentPlan();
  }

  fetchTreatmentPlan = async () => {
    const { match } = this.props;

    const { data: treatmentPlan } = await axios.get(
      `treatment-plan/${match.params.id_treatment_plan}`
    );

    this.setState({ treatmentPlan });
  };

  render() {
    const { treatmentPlan } = this.state;

    if (!treatmentPlan) {
      return (
        <Typography variant="h5" align="center">
          Chargement du plan de traitement...
        </Typography>
      );
    }

    return (
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Plan de traitement
          </Typography>
          <Typography variant="h5" align="center">
            {`${treatmentPlan.patient.fullname} - ${treatmentPlan.patient.reference_supplier}`}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="subtitle1" align="center">
            Nombre d'aligneurs
          </Typography>
          <Typography variant="h6" align="center">
            {treatmentPlan.nb_aligners}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="subtitle1" align="center">
            Durée
          </Typography>
          <Typography variant="h6" align="center">
            {treatmentPlan.nb_aligners / 2}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="subtitle1" align="center">
            Date de création
          </Typography>
          <Typography variant="h6" align="center">
            {format(parseISO(treatmentPlan.created_at), 'dd/MM/yyyy - HH:mm')}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="subtitle1" align="center">
            Disponible
          </Typography>
          <Typography variant="h6" align="center">
            {treatmentPlan.available_at && treatmentPlan.available
              ? 'Oui'
              : 'Non'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div>
            <Comments type="treatment-plan" id={treatmentPlan.id} />
          </div>
        </Grid>

        <Grid item xs={12}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {treatmentPlan.is_complete ? (
            treatmentPlan.type === 'interactive' ? (
              <InteractiveRender treatmentPlan={treatmentPlan} />
            ) : (
              <StaticRender treatmentPlan={treatmentPlan} />
            )
          ) : (
            <Upload
              treatmentPlan={treatmentPlan}
              fetchTreatmentPlan={this.fetchTreatmentPlan}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default Main;
