import React from 'react';
import Grid from '@material-ui/core/Grid';
import Upload from '../../Upload';

const angles = [
  { name: 'all', display: 'All Views' },
  { name: 'lateral_right', display: 'Lateral Right' },
  { name: 'facial_frontal', display: 'Facial (Frontal)' },
  { name: 'lateral_left', display: 'Lateral Left' },
  { name: 'upper_occlusal', display: 'Upper Occlusal' },
  { name: 'over_jet', display: 'Over Jet' },
  { name: 'lower_occlusal', display: 'Lower Occlusal' }
];

function TreatmentPlanUpload({ treatmentPlan, fetchTreatmentPlan }) {
  if (treatmentPlan.type === 'static') {
    return (
      <Grid container spacing={3}>
        {angles.map((angle) => (
          <Grid item xs={12} sm={4} md={3} key={angle.name}>
            <Upload
              receiverId={treatmentPlan.patient.id}
              receiverType="patient"
              resourceId={treatmentPlan.id}
              resourceType="treatment_plan"
              type="treatment_plan"
              meta={{
                angle: angle.name
              }}
              fileName={`plan-de-traitement-${treatmentPlan.patient.reference}`}
              description={`${angle.display}`}
              retryable
              autoUpload
              onSuccess={fetchTreatmentPlan}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} justify="center" alignContent="center">
      <Grid item xs={12} sm={4} md={3}>
        <Upload
          receiverId={treatmentPlan.patient.id}
          receiverType="patient"
          resourceId={treatmentPlan.id}
          resourceType="treatment_plan"
          type="treatment_plan"
          fileName={`plan-de-traitement-${treatmentPlan.patient.reference}`}
          description="Treatment plan model"
          onSuccess={fetchTreatmentPlan}
          retryable
          autoUpload
        />
      </Grid>
    </Grid>
  );
}

export default TreatmentPlanUpload;
