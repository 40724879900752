import React from 'react';

import Agenda from './Agenda';
import { SnackbarConsumer } from '../Snackbar';

const Main = () => (
  <SnackbarConsumer>
    {(snackbar) => <Agenda snackbar={snackbar} />}
  </SnackbarConsumer>
);

export default Main;
