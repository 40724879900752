import React, { Component } from 'react';
import Form from './FormPiece';
import axios from '../../../axios';

class AddPiece extends Component {
  getOrder() {
    const { pieces } = this.props;

    if (!pieces.length) return 1;

    return (
      // eslint-disable-next-line prefer-spread
      Math.max.apply(
        Math,
        pieces.map((o) => {
          return o.order;
        })
      ) + 1
    );
  }

  handleSubmit = async (values) => {
    try {
      await this.addPiece(values);
      return undefined;
    } catch ({ response: { data } }) {
      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  addPiece = async (values) => {
    const { feedId, fetchPieces, onCancel, snackbar } = this.props;

    await axios.post(`/feed/${feedId}/create-piece`, values);

    fetchPieces(feedId);
    snackbar.open('La pièce a bien été créee', 'success');
    onCancel();
  };

  render() {
    const { onCancel } = this.props;

    return (
      <Form
        initialValues={{
          order: this.getOrder()
        }}
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        submitText="Ajouter"
      />
    );
  }
}

export default AddPiece;
