import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  link: {
    textDecoration: 'none'
  }
});

const Breadcrumbs = ({ steps, classes }) => {
  const { length } = steps;

  return (
    <div className={classes.root}>
      {steps.map(({ url, name }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {index === length - 1 ? (
            <Typography variant="h5">{name}</Typography>
          ) : (
            <>
              <Typography to={url} component={Link} className={classes.link}>
                {name}
              </Typography>
              <ChevronRightIcon className={classes.chevron} />
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default withStyles(styles)(Breadcrumbs);
