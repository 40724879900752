import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import parseISO from 'date-fns/parseISO';
import axios from '../../../axios';
import { format } from '../../../format';

import DialogWithButton from '../../DialogWithButton';
import Loadable from '../../Loadable';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  actionContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  activePiece: {
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  done: {
    color: theme.palette.primary.main
  },
  onGoing: {
    color: theme.palette.secondary.main
  },
  stepper: {
    overflow: 'scroll'
  }
});

class Feed extends Component {
  isFetching = false;

  state = {
    feed: null,
    remaining: [],
    open: false,
    isFetching: true,
    active: null,
    activeIndex: 0,
    sortedPieces: null
  };

  componentDidMount = async () => {
    await this.fetchFeed();
  };

  componentDidUpdate = (prevProps) => {
    const { refresh } = this.props;
    if (prevProps.refresh !== refresh) {
      this.fetchFeed();
    }
  };

  setActivePiece = (name) => {
    const { sortedPieces } = this.state;

    const active = this.getFromName(name);

    const activeIndex =
      sortedPieces && sortedPieces.findIndex((p) => p.name === name);

    this.setState({ active, activeIndex });
  };

  fetchFeed = async () => {
    const { patientId, feedName, activePiece } = this.props;
    this.setState({ isFetching: true });

    try {
      const response = await axios.get(
        `/patient/${patientId}/feed/${feedName}`
      );
      const { feed, remaining_pieces: remaining } = response.data;

      this.setState({ feed, remaining, isFetching: false });
      this.sortPieces();

      this.setActivePiece(activePiece);
    } catch (error) {
      this.setState({ isFetching: false });
    }
  };

  handleValidatePiece = async () => {
    const { patientId, feedName, snackbar } = this.props;
    const { active } = this.state;

    try {
      await axios.get(`/patient/${patientId}/validate-piece`, {
        params: {
          feed_name: feedName,
          piece_name: active.name
        }
      });

      snackbar.open(
        `L'étape "${active.display}" a bien été validée`,
        'success'
      );

      this.fetchFeed();
      this.setOpen(false);
    } catch (error) {
      snackbar.open(
        `L'étape "${active.display}" n'a pas pu être validée`,
        'error'
      );
      this.setOpen(false);
    }
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  getPieceAtOrder = (order) => {
    const {
      feed: { pieces },
      remaining
    } = this.state;

    let index = null;

    index = pieces.findIndex((p) => p.order === order);

    if (index !== -1) {
      return { ...pieces[index], isRemaining: false };
    }

    index = remaining.findIndex((p) => p.order === order);

    if (index === -1) {
      return null;
    }

    return { ...remaining[index], isRemaining: true };
  };

  sortPieces = () => {
    const {
      feed: { pieces },
      remaining
    } = this.state;

    const total = pieces.length + remaining.length;
    let last = pieces.length ? pieces.slice(-1)[0].order : 1;
    let sortedPieces = [];

    if (remaining.length && last !== total) {
      last = remaining.slice(-1)[0].order;
    }

    while (last) {
      const piece = this.getPieceAtOrder(last);

      if (!piece) {
        last -= 1;
        // eslint-disable-next-line no-continue
        continue;
      }

      sortedPieces = [piece, ...sortedPieces];

      last -= 1;
    }

    this.setState({ sortedPieces });

    return sortedPieces;
  };

  getFromName = (name) => {
    const { sortedPieces } = this.state;

    return sortedPieces && sortedPieces.find((p) => p.name === name);
  };

  render() {
    const { classes, patientId, feedName, children } = this.props;
    const { active, activeIndex, feed, sortedPieces, isFetching } = this.state;

    const actions = (
      <Grid item xs={12}>
        <div className={classes.actionContainer}>
          <DialogWithButton
            buttonText="Valider l'étape"
            title="Valider cette étape"
            description="Voulez-vous valider cette étape?"
            onAccept={this.handleValidatePiece}
          />
        </div>
      </Grid>
    );

    const stepper = (
      <Stepper
        className={classes.stepper}
        alternativeLabel
        nonLinear
        activeStep={activeIndex}
      >
        {sortedPieces &&
          sortedPieces.map((piece, index) => {
            const props = {};
            const buttonProps = {};

            if (piece.isRemaining) {
              props.completed = false;
            } else {
              buttonProps.optional = (
                <Typography variant="caption">
                  {format(parseISO(piece.date), 'dd/MM/yyyy')}
                </Typography>
              );
            }
            return (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Step key={piece.name} {...props}>
                <StepButton
                  completed={!piece.isRemaining}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...buttonProps}
                  component={Link}
                  to={`/patient/${patientId}/${feedName}/${piece.name}`}
                  onClick={() => {
                    this.setState({ active: piece, activeIndex: index });
                  }}
                  icon={
                    piece.isRemaining ? (
                      piece.order
                    ) : (
                      <CheckCircleIcon
                        className={
                          piece.status === 'on-going'
                            ? classes.onGoing
                            : classes.done
                        }
                      />
                    )
                  }
                >
                  {piece.display}
                </StepButton>
              </Step>
            );
          })}
      </Stepper>
    );

    return (
      <Grid container>
        <Grid item xs={12} className={classes.root}>
          <Divider light />
          <Loadable loading={isFetching}>
            {feed && sortedPieces && stepper}
          </Loadable>
          <Divider light />
          <Typography variant="h5" className={classes.title}>
            {active && active.display}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle1"
            className={classes.title}
          >
            {active &&
              !active.isRemaining &&
              `Validé le ${format(parseISO(active.date), 'dd/MM/yyyy')}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>

        <Grid item xs={12}>
          {active && (active.isRemaining || !active.done) && actions}
        </Grid>
      </Grid>
    );
  }
}

Feed.propTypes = {
  patientId: PropTypes.number.isRequired,
  feedName: PropTypes.string.isRequired
};

export default withStyles(styles)(Feed);
