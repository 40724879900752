import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { differenceInCalendarYears, format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { Tag } from '../../Tags/Tag';
import { getLabelFromType } from '../../../utils/addresses.utils';

const styles = (theme) => ({
  avatar: {
    width: 110,
    height: 110,
    marginBottom: theme.spacing(2)
  },
  left: {
    textAlign: 'left'
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

const Informations = ({ classes, patient, addresses, infos, tags }) => (
  <Grid container>
    <Grid item sm={4}>
      <Avatar className={classes.avatar} alt={infos.fullname}>
        <Typography variant="h3">
          {infos.lastname.charAt(0)}
          {infos.firstname.charAt(0)}
        </Typography>
      </Avatar>

      <Typography variant="h5">{infos.fullname}</Typography>
    </Grid>
    <Grid item sm={4}>
      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Informations
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Reference : </span>
        {patient.reference}
      </Typography>
      {infos.birthday && (
        <Typography className={classes.left}>
          <span className={classes.label}>Date de naissance : </span>
          {format(parseISO(infos.birthday), 'dd/MM/yyyy')} (
          {differenceInCalendarYears(Date.now(), parseISO(infos.birthday))} ans)
        </Typography>
      )}
      <Typography className={classes.left}>
        <span className={classes.label}>Type : </span>
        {patient.type.charAt(0).toUpperCase() + patient.type.slice(1)}
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Sexe : </span>
        {infos.gender === 'm' ? 'Homme' : 'Femme'}
      </Typography>
      <Typography className={classes.left} color="error">
        <span className={classes.label}>Traitement : </span>
        {patient.treatment_type}
      </Typography>
      {patient.practician_id && (
        <Typography className={classes.left}>
          <span className={classes.label}>Praticien : </span>
          <Link to={`/practician/${patient.practician_id}`}>
            {patient.practician.fullname}
          </Link>
        </Typography>
      )}
      {patient.paid_at && (
        <Typography className={classes.left}>
          <span className={classes.label}>Prix : </span>
          {patient.price}€
        </Typography>
      )}
      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Tags
      </Typography>
      {tags.length ? (
        <div className={classes.tags}>
          {tags.map((item) => (
            <Tag key={item.id} data={item} />
          ))}
        </div>
      ) : (
        <Typography className={classes.left}>
          Patient has no tags assigned
        </Typography>
      )}
    </Grid>
    <Grid item sm={4}>
      <Typography className={classes.left} color="textSecondary" gutterBottom>
        Contact
      </Typography>
      <Typography className={classes.left}>
        <span className={classes.label}>Email : </span>
        <a target="_blank" href={`mailto:${infos.email}`} rel="noreferrer">
          {infos.email}
        </a>
      </Typography>
      {infos.mobile ? (
        <Typography className={classes.left} gutterBottom>
          <span className={classes.label}>Mobile : </span>
          <a target="_blank" href={`tel:${infos.mobile}`} rel="noreferrer">
            {infos.mobile}
          </a>
        </Typography>
      ) : null}

      {addresses &&
        addresses.map((address) => (
          <Fragment key={address.id}>
            <Typography
              className={classes.left}
              color="textSecondary"
              gutterBottom
            >
              {getLabelFromType(address.type)}
            </Typography>
            <Typography className={classes.left}>{address.address}</Typography>
            <Typography className={classes.left}>{address.address2}</Typography>
            <Typography className={classes.left} gutterBottom>
              {`${address.postal_code} ${address.city}`}
            </Typography>
          </Fragment>
        ))}
    </Grid>
  </Grid>
);

export default withStyles(styles)(Informations);
