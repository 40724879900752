import React from 'react';

import { Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import Feeds from './feeds/Main';

const styles = (theme) => ({
  root: {
    padding: theme.spacing()
  }
});

const Links = ({ match, classes }) => (
  <Paper className={classes.root}>
    <Link to={`${match.url}/feed`}>
      <Typography>Feeds</Typography>
    </Link>
    <a
      href={`${process.env.REACT_APP_API_URL}/telescope`}
      target="_blank"
      rel="noreferrer"
    >
      <Typography>Telescope</Typography>
    </a>
  </Paper>
);

const StyledLinks = withStyles(styles)(Links);

const Settings = ({ match }) => (
  <div>
    <Route exact path={`${match.url}/`} component={StyledLinks} />
    <Route path={`${match.url}/feed`} component={Feeds} />
  </div>
);

export default Settings;
