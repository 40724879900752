import React from 'react';

import Checkout from '../../../Checkout';

function Payment({ patient, fetchPatient }) {
  return (
    <Checkout
      price={patient.price}
      product="treatment"
      patient={patient}
      alreadyPaid={patient.paid}
      onSuccess={fetchPatient}
    />
  );
}

export default Payment;
