import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import { Form, Field } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import validator from 'validator';
import Select from '../final-form/Select';
import DatePicker from '../final-form/DatePicker';
import Places from '../final-form/Places';
import TextField from '../final-form/TextField';
import { getAddressPart, getCountry } from '../../utils/address.utils';

const styles = (theme) => ({
  wrapper: {
    padding: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(3)
  },
  rightIcon: {
    marginLeft: theme.spacing()
  }
});

const handleSelectAddress = async (placeId, form) => {
  const places = await geocodeByAddress(placeId);
  const place = places[0];
  const coordinates = await getLatLng(place);
  const address = {
    address: `${getAddressPart(place, 'street_number')} ${getAddressPart(
      place,
      'route'
    )}`,
    city: getAddressPart(place, 'locality'),
    postal_code: getAddressPart(place, 'postal_code'),
    country: getCountry(place),
    latitude: coordinates.lat,
    longitude: coordinates.lng
  };

  form.change('address', address.address);
  form.change('address_formatted', address.address);
  form.change('city', address.city);
  form.change('postal_code', address.postal_code);
  form.change('country', address.country);
  form.change('latitude', address.latitude);
  form.change('longitude', address.longitude);
};

const validate = (values) => {
  const errors = {};

  if (!values.firstname) {
    errors.firstname = 'Obligatoire';
  }
  if (!values.lastname) {
    errors.lastname = 'Obligatoire';
  }
  if (!values.gender) {
    errors.gender = 'Obligatoire';
  }
  if (!values.birthday) {
    errors.birthday = 'Obligatoire';
  }
  if (!values.address) {
    errors.address = 'Obligatoire';
  }
  if (!values.postal_code) {
    errors.postal_code = 'Obligatoire';
  }
  if (!values.city) {
    errors.city = 'Obligatoire';
  }
  if (!values.latitude) {
    errors.latitude = 'Obligatoire';
  }
  if (!values.longitude) {
    errors.longitude = 'Obligatoire';
  }
  if (!values.mobile) {
    errors.mobile = 'Obligatoire';
  } else if (!validator.isMobilePhone(values.mobile, ['fr-FR', 'fr-BE'])) {
    errors.mobile = 'Format invalide';
  }
  if (!values.email) {
    errors.email = 'Obligatoire';
  } else if (!validator.isEmail(values.email)) {
    errors.email = 'Email invalide';
  }
  if (!values.adeli) {
    errors.adeli = 'Obligatoire';
  }
  if (!values.type) {
    errors.type = 'Obligatoire';
  }
  if (!values.price_booking) {
    errors.price_booking = 'Obligatoire';
  }
  if (!values.price_treatment) {
    errors.price_treatment = 'Obligatoire';
  }

  return errors;
};

const FormPractician = ({ onSubmit, initialValues }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    validate={validate}
    render={({ handleSubmit, form, values }) => (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Informations personnelles
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              name="firstname"
              label="Prénom"
              component={TextField}
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              name="lastname"
              label="Nom"
              component={TextField}
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              formControlProps={{ fullWidth: true }}
              name="gender"
              label="Sexe"
              component={Select}
            >
              <MenuItem value="m">Homme</MenuItem>
              <MenuItem value="f">Femme</MenuItem>
            </Field>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              name="birthday"
              label="Date de naissance"
              render={(props) => (
                <DatePicker
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  clearable
                  format="dd/MM/yyyy"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">Contact</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Places
              fullWidth
              name="address"
              label="Adresse"
              onChange={() => {}}
              onSelect={(address) => {
                handleSelectAddress(address, form);
              }}
              value={values.address || ''}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              fullWidth
              name="address3"
              label="Acces"
              component={TextField}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field fullWidth name="city" label="Ville" component={TextField} />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              fullWidth
              name="postal_code"
              label="Code postal"
              component={TextField}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field fullWidth name="email" label="Email" component={TextField} />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              fullWidth
              name="mobile"
              label="Num. portable"
              component={TextField}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              fullWidth
              name="landline"
              label="Num. fixe"
              component={TextField}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Informations professionnelles
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field name="adeli" label="RPPS" component={TextField} fullWidth />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              formControlProps={{ fullWidth: true }}
              name="type"
              label="Type de praticien"
              component={Select}
            >
              <MenuItem value="dentist">Dentiste</MenuItem>
              <MenuItem value="orthodontist">Orthodontiste</MenuItem>
            </Field>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              name="price_booking"
              label="Prix RDV"
              component={TextField}
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              name="price_treatment"
              label="Prix Traitement"
              component={TextField}
              fullWidth
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Field
              name="name"
              label="Nom du cabinet"
              component={TextField}
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field name="alias" label="Alias" component={TextField} fullWidth />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              name="password"
              label="Mot de passe"
              type="password"
              component={TextField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Rendez-vous</Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              formControlProps={{ fullWidth: true }}
              name="availabilities_source"
              label="Source disponibilités"
              component={Select}
            >
              <MenuItem value="joovence">Joovence</MenuItem>
              <MenuItem value="doctolib">Doctolib</MenuItem>
              <MenuItem value="clicrdv">ClicRDV</MenuItem>
            </Field>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Field
              name="duration_booking"
              label="Durée des consulations (en minutes)"
              component={TextField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Envoyer
            </Button>
          </Grid>
        </Grid>
      </form>
    )}
  />
);

export default withStyles(styles)(FormPractician);
