import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';

import Feed from '../Feed';
import Payment from './Payment';
import ChooseDate from './ChooseDate';
import CreateCase from './CreateCase';
import PhotosUpload from './PhotosUpload';
import Confirmation from './Confirmation';
import Status from './Status';
import Consultation from './Consultation';

const styles = (theme) => ({
  root: {
    textAlign: 'left',
    padding: theme.spacing(2)
  }
});

class Booking extends Component {
  state = {
    refreshFeed: false
  };

  componentDidMount = () => {
    this.fetchPatient();
  };

  fetchPatient = () => {
    const { fetchPatient } = this.props;
    const { refreshFeed } = this.state;
    fetchPatient();
    this.setState({ refreshFeed: !refreshFeed });
  };

  render() {
    const {
      patient: { booking },
      patient,
      classes,
      match,
      snackbar
    } = this.props;

    const { refreshFeed } = this.state;

    return (
      <div className={classes.root}>
        <Feed
          patientId={patient.id}
          snackbar={snackbar}
          activePiece={match.params.piece}
          feedName="booking"
          refresh={refreshFeed}
        >
          <Switch>
            <Route
              exact
              path="/patient/:id/booking/choose_date"
              render={(props) => (
                <ChooseDate
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  booking={booking}
                  patient={patient}
                  fetchPatient={this.fetchPatient}
                />
              )}
            />
            <Route
              exact
              path="/patient/:id/booking/payment"
              render={(props) => (
                <Payment
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  patient={patient}
                  fetchPatient={this.fetchPatient}
                />
              )}
            />
            <Route
              exact
              path="/patient/:id/booking/photos_upload"
              render={(props) => (
                <PhotosUpload
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  booking={booking}
                  patient={patient}
                  fetchPatient={this.fetchPatient}
                />
              )}
            />
            <Route
              exact
              path="/patient/:id/booking/create_case"
              render={(props) => (
                <CreateCase
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  booking={booking}
                  patient={patient}
                  fetchPatient={this.fetchPatient}
                />
              )}
            />
            <Route
              exact
              path="/patient/:id/booking/confirmation_supplier"
              render={(props) => (
                <Confirmation
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  booking={booking}
                  patient={patient}
                  fetchPatient={this.fetchPatient}
                />
              )}
            />
            <Route
              exact
              path="/patient/:id/booking/confirmation_booking"
              render={() => <Status booking={booking} />}
            />
            <Route
              exact
              path="/patient/:id/booking/consultation"
              render={() => (
                <Consultation
                  patient={patient}
                  fetchPatient={this.fetchPatient}
                />
              )}
            />
          </Switch>
        </Feed>
      </div>
    );
  }
}

export default withStyles(styles)(Booking);
