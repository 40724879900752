/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Upload from '../../../Upload';

const angles = [
  { name: 'occlussal_upper', display: 'Occlusal upper' },
  { name: 'occlussal_lower', display: 'Occlusal lower' },
  { name: 'buccal_left', display: 'Buccal left' },
  { name: 'buccal_right', display: 'Buccal right' },
  { name: 'labial_anterior', display: 'Labial anterior' }
];

class PhotosUpload extends Component {
  render() {
    const { patient } = this.props;

    const serie = 'pre_assessment';

    return (
      <Grid container spacing={3}>
        {angles.map((angle) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={angle.name}>
              <Upload
                receiverId={patient.id}
                receiverType="patient"
                type="treatment_photo"
                meta={{
                  angle: angle.name,
                  serie
                }}
                fileName={`photo-${angle.name}-${serie}-${patient.reference}`}
                description={angle.display}
                retryable
                autoUpload
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default PhotosUpload;
