import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Add from './AddFeed';
import Edit from './EditFeed';
import Dialog from '../../Dialog';

import axios from '../../../axios';

const styles = () => ({
  active: {
    borderLeft: '2px solid black'
  }
});

class Feeds extends Component {
  state = {
    feeds: null,
    activeFeed: null,
    addMode: false,
    editMode: false,
    toEdit: null,
    toDelete: null,
    deleteMode: false
  };

  componentDidMount() {
    this.fetchFeeds();
  }

  selectFeed = (activeFeed) => {
    this.setState({ activeFeed });
  };

  setAddMode = (addMode) => {
    this.setState({ addMode });
  };

  handleDelete = (feed) => {
    this.setState({ deleteMode: true, toDelete: feed });
  };

  handleEdit = (feed) => {
    this.setState({ editMode: true, toEdit: feed });
  };

  onDelete = async () => {
    const { toDelete } = this.state;
    const { snackbar } = this.props;
    try {
      await axios.delete(`feed/${toDelete.id}`);
      this.fetchFeeds();
      snackbar.open('Le feed a bien été supprimé', 'success');
    } catch (error) {
      snackbar.open("Le feed n'a pas pu être supprimé", 'error');
    }

    this.setState({ deleteMode: false });
  };

  fetchFeeds = async () => {
    const response = await axios.get('/feeds');
    this.setState({ feeds: response.data });
  };

  render() {
    const { classes, snackbar } = this.props;
    const {
      feeds,
      activeFeed,
      addMode,
      toDelete,
      deleteMode,
      editMode,
      toEdit
    } = this.state;
    if (!feeds) {
      return null;
    }

    return (
      <List component="nav">
        {editMode ? (
          <Edit
            fetchFeeds={this.fetchFeeds}
            onCancel={() => {
              this.setState({ editMode: false });
            }}
            feed={toEdit}
            snackbar={snackbar}
          />
        ) : (
          feeds.map((feed) => (
            <ListItem
              key={feed.id}
              button
              onClick={() => this.selectFeed(feed.id)}
              component={Link}
              to={`/settings/feed/${feed.id}`}
              className={activeFeed === feed.id ? classes.active : ''}
            >
              <ListItemText primary={feed.display} secondary={feed.name} />
              <ListItemSecondaryAction>
                <IconButton
                  aria-label="Edit"
                  onClick={() => {
                    this.handleEdit(feed);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="Delete"
                  onClick={() => {
                    this.handleDelete(feed);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}

        {addMode ? (
          <Add
            fetchFeeds={this.fetchFeeds}
            onCancel={() => {
              this.setAddMode(false);
            }}
            snackbar={snackbar}
          />
        ) : (
          <ListItem button onClick={() => this.setAddMode(true)}>
            <Avatar>
              <AddIcon />
            </Avatar>
            <ListItemText primary="Ajouter" />
          </ListItem>
        )}

        <Dialog
          title="Voulez-vous supprimer ce feed?"
          description={`Suppression du feed ${
            toDelete ? toDelete.name : ''
          }. Cette action est irréversible.`}
          open={deleteMode}
          onCancel={() => {
            this.setState({ deleteMode: false });
          }}
          onAccept={this.onDelete}
        />
      </List>
    );
  }
}

export default withStyles(styles)(Feeds);
