import React from 'react';

import Typography from '@material-ui/core/Typography';

import Bookings from './Bookings';
import Production from './Production';

const Dashboard = () => (
  <div>
    <Typography variant="h5">Dashboard</Typography>

    <Bookings />
    <Production />
  </div>
);

export default Dashboard;
