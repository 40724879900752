export const consultationQuestions = [
  {
    name: 'premolars',
    expectedAnswer: 'no',
    fr: 'Présence de couronnes, implants ou bridges sur prémolaires ?',
    en: 'Presence of crowns, implants or bridges on premolars?'
  },
  {
    name: 'whitening',
    expectedAnswer: 'yes',
    fr: "Éligible solution de blanchiment (3,8% de perox. d'hydrogène)",
    en: 'Éligible whitening solution (3,8% hydrogen peroxide)'
  },
  {
    name: 'descaling',
    expectedAnswer: 'no',
    fr: 'Détartrage à effectuer ?',
    en: 'Descaling necessary?'
  },
  {
    name: 'decay',
    expectedAnswer: 'no',
    fr: 'Caries à soigner ?',
    en: 'Presence of decay?'
  },
  {
    name: 'fillings',
    expectedAnswer: 'no',
    fr: 'Présence de plombage(s) en mauvais état ?',
    en: 'Presence of loose or broken fillings?'
  },
  {
    name: 'loose_teeth',
    expectedAnswer: 'no',
    fr: 'Présence de dents qui bougent ?',
    en: 'Presence of loose teeth?'
  },
  {
    name: 'roots',
    expectedAnswer: 'no',
    fr: 'Présence de racine(s) dentaire(s) raccourcie(s) ou résorbée(s) ?',
    en: 'Shortened or resorbed tooth roots?'
  },
  {
    name: 'non_erupted',
    expectedAnswer: 'no',
    fr: 'Présence de dent(s) éruptée(s), semi-incluse(s) ou incluse(s) ?',
    en: 'Presence of impacted/non-erupted teeth?'
  },
  {
    name: 'gum',
    expectedAnswer: 'no',
    fr: 'Présence de maladie(s) des gencives / problèmes de parodontie ?',
    en: 'Presence of gum disease?'
  },
  {
    name: 'cancer',
    expectedAnswer: 'no',
    fr: 'Suspicion de cancer oral ?',
    en: 'Suspected oral cancer?'
  },
  {
    name: 'tmj',
    expectedAnswer: 'no',
    fr: "Limitation de l'ouverture buccale / Problèmes d'ATM ?",
    en: 'Limited mouth opening / TMJ problem?'
  }
];
