import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { differenceInDays, addDays } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import axios from '../../../../axios';
import { format } from '../../../../format';
import PhotosUpload from '../../../PhotosUpload';

class Aligner extends Component {
  state = {
    aligner: null
  };

  componentDidMount() {
    this.fetchAligner();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { match: prevMatch } = prevProps;

    if (match.params.serie !== prevMatch.params.serie) {
      this.fetchAligner();
    }
  }

  fetchAligner = async () => {
    const { patient, match } = this.props;

    this.setState({ aligner: null });

    const { data: aligner } = await axios.get(
      `/patient/${patient.id}/serie/${match.params.serie}`
    );

    this.setState({ aligner });
  };

  render() {
    const { aligner } = this.state;
    const { patient } = this.props;

    if (!aligner) {
      return null;
    }

    const daysWorn = differenceInDays(
      parseISO(aligner.finished_at),
      parseISO(aligner.started_at)
    );

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <IconButton
            color="primary"
            aria-label="Aligneurs"
            component={Link}
            to={`/patient/${patient.id}/treatment`}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography gutterBottom variant="h6">
            Aligneur {aligner.serie} / {patient.nb_aligners}
          </Typography>
          {aligner.serie !== 1 && (
            <IconButton
              color="primary"
              aria-label="Aligneurs"
              component={Link}
              to={`/patient/${patient.id}/treatment/${aligner.serie - 1}`}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          {patient.actual_aligners.serie !== aligner.serie && (
            <IconButton
              color="primary"
              aria-label="Aligneurs"
              component={Link}
              to={`/patient/${patient.id}/treatment/${aligner.serie + 1}`}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary">Début</Typography>
          <Typography gutterBottom>
            {format(parseISO(aligner.started_at), 'dd MMMM yyyy')}
          </Typography>
          <Typography color="textSecondary">Fin</Typography>
          {aligner.finished_at ? (
            <Typography gutterBottom>{`${format(
              parseISO(aligner.finished_at),
              'dd MMMM yyyy'
            )}`}</Typography>
          ) : (
            <Typography gutterBottom>{`${format(
              addDays(parseISO(aligner.started_at), 15),
              'dd MMMM yyyy'
            )}`}</Typography>
          )}
        </Grid>
        <Grid item sm={3} xs={6}>
          {aligner.finished_at && (
            <>
              <Typography color="textSecondary">Nb de jours portés</Typography>

              <Typography
                gutterBottom
                color={daysWorn < 15 || daysWorn > 20 ? 'error' : 'initial'}
              >
                {daysWorn} jours
              </Typography>
            </>
          )}
          <Typography color="textSecondary">Progression</Typography>
          <Typography gutterBottom>
            {Math.round((aligner.serie * 100) / patient.nb_aligners)}%
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PhotosUpload
            patient={patient}
            version={aligner.version}
            serie={aligner.serie}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Aligner;
