import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import TextField from '../../final-form/TextField';
import { formatComments } from '../list/exportToSupplier/exportPatient.format';
import Comments from '../view/comments/Comments';
import Loadable from '../../Loadable';

const ExportForm = ({ onSubmit, patient }) => {
  return (
    <>
      <Comments type="patient" id={patient.id} readOnly />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          comment: formatComments(patient.comments)
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  disabled={submitting}
                  name="comment"
                  label="Comment for supplier"
                  multiline
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Loadable loading={submitting} message="Exporting patient...">
                  <Button variant="contained" color="primary" type="submit">
                    Send
                  </Button>
                </Loadable>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  );
};
export default ExportForm;
