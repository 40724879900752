import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  progress: {
    marginBottom: theme.spacing(2)
  }
});

const Loadable = ({ loading, children, classes, message, size, noMessage }) => {
  if (loading) {
    if (noMessage) {
      return <CircularProgress size={size || 40} color="secondary" />;
    }

    return (
      <div className={classes.root}>
        <CircularProgress
          size={size || 40}
          className={classes.progress}
          color="secondary"
        />
        <Typography>{message || 'Chargement...'}</Typography>
      </div>
    );
  }

  return children;
};

export default withStyles(styles)(Loadable);
