import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import TextField from '../../final-form/TextField';
import Checkbox from '../../final-form/Checkbox';

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(2)
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  input: {
    marginRight: theme.spacing()
  }
});

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Obligatoire';
  }
  if (!values.order) {
    errors.order = 'Obligatoire';
  }
  if (!values.display) {
    errors.display = 'Obligatoire';
  }

  return errors;
};

const FormPiece = ({
  onSubmit,
  onCancel,
  classes,
  submitText,
  initialValues
}) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    initialValues={initialValues}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Field name="order" label="Ordre" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Field name="name" label="Nom" component={TextField} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Field
              name="display"
              label="Affichage"
              component={TextField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="checkbox"
              name="action_required"
              label="Action requise"
              component={Checkbox}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
        >
          {submitText}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          onClick={onCancel}
        >
          Annuler
        </Button>
      </form>
    )}
  />
);

export default withStyles(styles)(FormPiece);
