import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {
  DataTypeProvider,
  SearchState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';

import {
  Grid as GridDX,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';

import { Typography } from '@material-ui/core';
import parseISO from 'date-fns/parseISO';
import axios from '../../../../axios';
import DialogWithButton from '../../../DialogWithButton';
import { format } from '../../../../format';

const statusList = [
  { name: 'pending', display: 'En attente' },
  { name: 'confirmed', display: 'Confirmé' },
  { name: 'canceled', display: 'Annulé' },
  { name: 'not_honored', display: 'Non Honoré' },
  { name: 'done', display: 'Terminé' }
];
const sourceList = [
  { name: 'joovence', display: 'Joovence' },
  { name: 'doctolib', display: 'Doctolib' },
  { name: 'clicrdv', display: 'Clicrdv' }
];

const typeList = [
  { name: 'pre_treatment', display: 'Début de traitement' },
  { name: 'mid_treatment', display: 'Mi-traitement' },
  { name: 'end_treatment', display: 'Fin de traitement' },
  { name: 'urgent', display: 'Urgence' },
  { name: 'other', display: 'Autre' }
];

const LinkFormatter = ({ row, value, column }) => (
  <Link to={`/${column.name}/${row.practician.id}`}>{value}</Link>
);

const LinkTypeProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DataTypeProvider formatterComponent={LinkFormatter} {...props} />
);

const PriceFormatter = ({ row }) => {
  const price = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR'
  }).format(row.price);

  return (
    <Typography color={row.paid_at ? 'initial' : 'error'}>
      <strong>{price}</strong>
      {row.paid_at
        ? ` - Payé le ${format(parseISO(row.paid_at), 'dd/MM/yyyy - HH:mm')}`
        : ' - Non payé'}
    </Typography>
  );
};

const PriceTypeProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DataTypeProvider formatterComponent={PriceFormatter} {...props} />
);

class ListBookings extends Component {
  cancel = async (booking_id) => {
    const { snackbar, fetchBookings } = this.props;
    try {
      await axios.get(`booking/${booking_id}/cancel`);
      await snackbar.open('Le rendez-vous a été annulé', 'success');
      await fetchBookings();
    } catch (error) {
      await snackbar.open(error.response.data.message, 'error');
    }
  };

  render() {
    const { bookings } = this.props;

    if (!bookings) return null;

    return (
      <Grid container>
        <Grid item xs={12}>
          <GridDX
            rows={bookings}
            columns={[
              {
                title: 'ID',
                name: 'id'
              },
              {
                title: 'Praticien',
                name: 'practician',
                getCellValue: (booking) => {
                  return booking.practician.fullname;
                }
              },
              {
                title: 'Date',
                name: 'date',
                getCellValue: (booking) => {
                  return format(parseISO(booking.date), 'dd/MM/yyyy - HH:mm');
                }
              },
              {
                name: 'status',
                title: 'Status',
                getCellValue: (booking) => {
                  return statusList.find((s) => s.name === booking.status)
                    .display;
                }
              },
              {
                name: 'source',
                title: 'Source',
                getCellValue: (booking) => {
                  return sourceList.find((s) => s.name === booking.source)
                    .display;
                }
              },
              {
                name: 'type',
                title: 'Type',
                getCellValue: (booking) => {
                  return typeList.find((t) => t.name === booking.type).display;
                }
              },
              {
                name: 'price',
                title: 'Prix',
                getCellValue: (booking) => {
                  return booking.price;
                }
              },
              {
                name: 'created_at',
                title: 'Date de création',
                getCellValue: (booking) => {
                  return format(
                    parseISO(booking.created_at),
                    'dd/MM/yyyy - HH:mm'
                  );
                }
              },
              {
                name: 'actions',
                title: 'Actions',
                getCellValue: (booking) => {
                  if (booking.status !== 'confirmed') return null;

                  return (
                    <DialogWithButton
                      title="Annuler RDV"
                      description="Annuler ce rendez-vous?"
                      buttonText="Annuler"
                      onAccept={() => this.cancel(booking.id)}
                      color="secondary"
                    />
                  );
                }
              }
            ]}
          >
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <LinkTypeProvider for={['practician']} />
            <PriceTypeProvider for={['price']} />
            <Table columnExtensions={[{ columnName: 'practician' }]} />
            <TableHeaderRow />
            <Toolbar />
            <SearchPanel />
          </GridDX>
        </Grid>
      </Grid>
    );
  }
}

export default ListBookings;
