import { format as fnsFormat, formatDistance } from 'date-fns';
import { fr } from 'date-fns/locale';

/**
 * Doc : https://date-fns.org/v1.29.0/docs/format
 */

// var locales = {
//   en: require("date-fns/locale/en"),
//   fr: require("date-fns/locale/fr")
// };

export const format = (date, formatStr, options = {}) => {
  return fnsFormat(date, formatStr, {
    ...options,
    locale: fr
  });
};

export const formatDateTime = (date) => {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

const myFormatDistance = (date, baseDate, options = {}) => {
  return formatDistance(date, baseDate, {
    ...options,
    locale: fr
  });
};

export { format as default, myFormatDistance as formatDistance };
