import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BookingIcon from '@material-ui/icons/Schedule';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CommentIcon from '@material-ui/icons/Comment';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { PATIENT_TABS } from './helpers';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
});

class PatientTabs extends Component {
  change = (value) => {
    const { changeTab } = this.props;
    changeTab(value);
  };

  render() {
    const { classes, url, value } = this.props;
    return (
      <Tabs
        value={value}
        indicatorColor="secondary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.root}
      >
        <Tab
          icon={<BookingIcon />}
          onClick={() => this.change(PATIENT_TABS.booking)}
          component={Link}
          to={`${url}/${PATIENT_TABS.booking}`}
          value={PATIENT_TABS.booking}
          label="Booking"
        />
        <Tab
          icon={<FavoriteIcon />}
          onClick={() => this.change(PATIENT_TABS.production)}
          component={Link}
          to={`${url}/${PATIENT_TABS.production}`}
          value={PATIENT_TABS.production}
          label="Production"
        />
        <Tab
          icon={<FavoriteIcon />}
          onClick={() => this.change(PATIENT_TABS.treatment)}
          component={Link}
          to={`${url}/${PATIENT_TABS.treatment}`}
          value={PATIENT_TABS.treatment}
          label="Treatment"
        />
        <Tab
          icon={<CommentIcon />}
          onClick={() => this.change(PATIENT_TABS.comments)}
          component={Link}
          to={`${url}/${PATIENT_TABS.comments}`}
          value={PATIENT_TABS.comments}
          label="Commentaires"
        />
        <Tab
          icon={<CalendarTodayIcon />}
          onClick={() => this.change(PATIENT_TABS.bookings)}
          component={Link}
          to={`${url}/${PATIENT_TABS.bookings}`}
          value={PATIENT_TABS.bookings}
          label="Bookings"
        />
        <Tab
          icon={<LocalOfferIcon />}
          onClick={() => this.change(PATIENT_TABS.tags)}
          component={Link}
          to={`${url}/${PATIENT_TABS.tags}`}
          value={PATIENT_TABS.tags}
          label="Manage tags"
        />
      </Tabs>
    );
  }
}

export default withStyles(styles)(PatientTabs);
