import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Add from './AddPiece';
import Edit from './EditPiece';
import Dialog from '../../Dialog';
import axios from '../../../axios';

class Pieces extends Component {
  state = {
    pieces: null,
    addMode: false,
    editMode: false,
    deleteOpen: false,
    toEdit: null,
    toDelete: null
  };

  fetchPieces = async (feedId) => {
    const response = await axios.get(`/feed/${feedId}`);

    this.setState({ pieces: response.data.pieces });
  };

  componentDidMount = () => {
    const {
      match: { params }
    } = this.props;

    this.fetchPieces(params.feedId);
  };

  componentDidUpdate = (prevProps) => {
    const { match } = this.props;

    if (prevProps.match.params.feedId !== match.params.feedId) {
      this.setAddMode(false);
      this.fetchPieces(match.params.feedId);
    }
  };

  setAddMode = (addMode) => {
    this.setState({ addMode });
  };

  setEditMode = (editMode) => {
    this.setState({ editMode });
  };

  openDelete = (piece) => {
    this.setState({ toDelete: piece, deleteOpen: true });
  };

  handleDelete = async () => {
    const { snackbar, match } = this.props;
    const { toDelete } = this.state;
    try {
      await axios.delete(`piece/${toDelete.id}`);
      this.fetchPieces(match.params.feedId);
      snackbar.open('La pièce a bien été supprimée', 'success');
    } catch (error) {
      snackbar.open("La pièce n'a pas pu être supprimée", 'error');
    }

    this.setState({ deleteOpen: false });
  };

  handleEdit = (piece) => {
    this.setState({ toEdit: piece, editMode: true });
  };

  render() {
    const {
      match: { params },
      snackbar
    } = this.props;
    const { pieces, addMode, editMode, toEdit, toDelete, deleteOpen } =
      this.state;

    if (!pieces) return null;

    return (
      <List component="nav">
        {editMode ? (
          <Edit
            fetchPieces={this.fetchPieces}
            feedId={params.feedId}
            onCancel={() => {
              this.setEditMode(false);
            }}
            piece={toEdit}
            snackbar={snackbar}
          />
        ) : (
          pieces
            .sort((a, b) => (a.order < b.order ? -1 : 1))
            .map((piece) => (
              <ListItem key={piece.id}>
                <ListItemText
                  primary={piece.display}
                  secondary={`${piece.order} - ${piece.name} ${
                    piece.action_required ? '- Action requise' : ''
                  }`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => {
                      this.handleEdit(piece);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => {
                      this.openDelete(piece);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
        )}

        <Dialog
          title="Voulez-vous supprimer cette pièce?"
          description={`Suppression de la pièce "${
            toDelete ? toDelete.name : ''
          }". Cette action est irréversible.`}
          open={deleteOpen}
          onCancel={() => {
            this.setState({ deleteOpen: false });
          }}
          onAccept={this.handleDelete}
        />

        {addMode ? (
          <Add
            feedId={params.feedId}
            pieces={pieces}
            fetchPieces={this.fetchPieces}
            onCancel={() => {
              this.setAddMode(false);
            }}
            snackbar={snackbar}
          />
        ) : (
          <ListItem button onClick={() => this.setAddMode(true)}>
            <Avatar>
              <AddIcon />
            </Avatar>
            <ListItemText primary="Ajouter" />
          </ListItem>
        )}
      </List>
    );
  }
}

export default Pieces;
