import React from 'react';
import moment from 'moment';
import Dialog from '../../Dialog';

export const ShowBooking = ({ event, onClose }) => {
  if (!event) return null;
  return (
    <Dialog
      open
      title="Consultation"
      description={`Consultation avec ${event.title} le ${moment(
        event.start
      ).format('DD/MM')} à ${moment(event.start).format('HH:mm')}`}
      onAccept={onClose}
      confirmText="Fermer"
    />
  );
};
