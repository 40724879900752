import React, { Component } from 'react';
import Form from './FormFeed';
import axios from '../../../axios';

class AddFeed extends Component {
  handleSubmit = async (values) => {
    try {
      await this.addFeed(values);
      return undefined;
    } catch ({ response: { data } }) {
      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  addFeed = async (model) => {
    const { feedId, fetchFeeds, onCancel, snackbar } = this.props;

    await axios.post(`/feed/create`, model);

    fetchFeeds(feedId);
    snackbar.open('Le feed a bien été créee', 'success');
    onCancel();
  };

  render() {
    const { onCancel } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        submitText="Ajouter"
      />
    );
  }
}

export default AddFeed;
