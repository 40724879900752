import React from 'react';

import Button from '@material-ui/core/Button';

import Balance from '../Balance';

function Charges({ practician }) {
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write`}
        target="_blank"
      >
        Lier Stripe
      </Button>

      <Balance practicianId={practician.id} />
    </div>
  );
}

export default Charges;
