import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import Loadable from '../Loadable';
import axios from '../../axios';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  }
});

class Production extends Component {
  state = {
    patients: null,
    isFetching: false
  };

  componentDidMount() {
    this.fetchPatients();
  }

  fetchPatients = async () => {
    try {
      this.setState({ isFetching: true });
      const { data: patients } = await axios.get('dashboard/production');
      this.setState({ patients, isFetching: false });
    } catch (error) {
      this.setState({ isFetching: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { patients, isFetching } = this.state;

    const counts = patients && (
      <Grid container spacing={3}>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Photos
          </Typography>
          <Typography variant="h5" align="center">
            {patients.photos}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Empreintes
          </Typography>
          <Typography variant="h5" align="center">
            {patients.scans}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Plan de traitement
          </Typography>
          <Typography variant="h5" align="center">
            {patients.pending_treatment_plan}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Décision praticien
          </Typography>
          <Typography variant="h5" align="center">
            {patients.decision_practician}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Décision patient
          </Typography>
          <Typography variant="h5" align="center">
            {patients.decision_patient}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Paiement
          </Typography>
          <Typography variant="h5" align="center">
            {patients.decision_patient}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Fabrication
          </Typography>
          <Typography variant="h5" align="center">
            {patients.aligners_fabrication}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Livraison
          </Typography>
          <Typography variant="h5" align="center">
            {patients.aligners_shipped}
          </Typography>
        </Grid>
      </Grid>
    );

    return (
      <Paper className={classes.root}>
        <Typography variant="subtitle1">Production</Typography>
        <Loadable loading={isFetching}>{counts}</Loadable>
      </Paper>
    );
  }
}

export default withStyles(styles)(Production);
