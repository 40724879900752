import React from 'react';
import moment from 'moment';
import axios from '../../../axios';
import Dialog from '../../Dialog';

export const DeleteAvailability = ({
  event,
  snackbar,
  onDelete,
  onCancel,
  practician
}) => {
  const deleteAvailability = async () => {
    try {
      await axios.delete(
        `practician/${practician.id}/availability/${event.id}`
      );
      snackbar.open('La disponibilité a bien été supprimée', 'success');
    } catch (error) {
      snackbar.open("La disponibilité n'a pas pu être supprimée", 'error');
    } finally {
      await onDelete();
    }
  };

  if (!event) return null;
  return (
    <Dialog
      open
      title="Supprimer la disponibilité"
      description={`Voulez-vous supprimer votre disponibilité du ${moment(
        event.start
      ).format('DD/MM')} ${moment(event.start).format('HH:mm')} - ${moment(
        event.end
      ).format('HH:mm')}`}
      onCancel={onCancel}
      onAccept={deleteAvailability}
      cancelText="Annuler"
      confirmText="Supprimer"
    />
  );
};
