import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SnackbarConsumer } from '../Snackbar';

import List from './ListPracticians';
import Create from './CreatePractician';
import View from './ViewPractician';
import Edit from './EditPractician';
import LinkStripe from './LinkStripe';

function Practicians() {
  return (
    <Switch>
      <SnackbarConsumer>
        {(snackbar) => (
          <>
            <Switch>
              <Route exact path="/practicians" component={List} />
              <Route
                exact
                path="/practician/link-stripe"
                render={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <LinkStripe {...props} snackbar={snackbar} />
                )}
              />
              <Route
                exact
                path="/practician/create"
                // eslint-disable-next-line react/jsx-props-no-spreading
                render={(props) => <Create {...props} snackbar={snackbar} />}
              />
              <Route
                exact
                path="/practician/:id/edit"
                // eslint-disable-next-line react/jsx-props-no-spreading
                render={(props) => <Edit {...props} snackbar={snackbar} />}
              />
              <Route
                path="/practician/:id/:tab?"
                // eslint-disable-next-line react/jsx-props-no-spreading
                render={(props) => <View {...props} snackbar={snackbar} />}
              />
            </Switch>
          </>
        )}
      </SnackbarConsumer>
    </Switch>
  );
}

export default Practicians;
