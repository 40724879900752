import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import theme from './theme';

import Layout from './components/Layout';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
        <Layout />
      </Router>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);

export default App;
