const ADDRESS_TYPE_MAIN = 'main';
const ADDRESS_TYPE_DELIVERY = 'delivery';

const getLabelFromType = (type) => {
  switch (type) {
    case ADDRESS_TYPE_MAIN:
      return 'Adresse de facturation';
    case ADDRESS_TYPE_DELIVERY:
      return 'Adresse de livraison';
    default:
      return 'Adresse';
  }
};

export { getLabelFromType };
