import React, { Component, Fragment } from 'react';

import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';
import { SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';

import { Typography } from '@material-ui/core';
import parseISO from 'date-fns/parseISO';

import Comments from '../comments/Comments';
import { format } from '../../../../format';
import axios from '../../../../axios';

class TreatmentPlanUpload extends Component {
  state = {
    treatmentPlans: [],
    errors: {}
  };

  async componentDidMount() {
    await this.fetchTreatmentPlans();
  }

  fetchTreatmentPlans = async () => {
    const { patient } = this.props;
    const { data: treatmentPlans } = await axios.get(
      `/patient/${patient.id}/treatment-plans`
    );

    this.setState({ treatmentPlans });
  };

  render() {
    const { patient } = this.props;
    const { treatmentPlans } = this.state;

    return (
      <>
        <Comments type="patient" id={patient.id} />
        <Typography
          gutterBottom
          color={patient.health_questions ? 'initial' : 'error'}
        >
          {`Réponses questionnaire : ${
            patient.health_questions ? 'Effectué' : 'Non effectué'
          }`}
        </Typography>
        <Typography
          gutterBottom
          color={patient.accepted_allow_transfer_data_at ? 'initial' : 'error'}
        >
          {`Autorisation transfert de données : ${
            patient.accepted_allow_transfer_data_at
              ? 'Effectué'
              : 'Non effectué'
          }`}
        </Typography>
        {!!treatmentPlans.length && (
          <Grid
            rows={treatmentPlans}
            columns={[
              {
                name: 'date',
                title: 'Date',
                getCellValue: (treatmentPlan) => {
                  return (
                    <a
                      target="_blank"
                      href={`/patient/${patient.id}/treatment-plan/${treatmentPlan.id}`}
                      rel="noreferrer"
                    >
                      {format(parseISO(treatmentPlan.created_at), 'dd/MM/yyyy')}
                    </a>
                  );
                }
              },
              {
                name: 'nb_aligners',
                title: 'Nn aligneurs'
              },
              {
                name: 'type',
                title: 'Type',
                getCellValue: (treatmentPlan) => {
                  return treatmentPlan.type === 'static'
                    ? 'Statique'
                    : 'Interactif';
                }
              },
              {
                name: 'is_complete',
                title: 'Complet',
                getCellValue: (treatmentPlan) => {
                  return treatmentPlan.is_complete ? 'Oui' : 'Non';
                }
              },
              {
                name: 'available',
                title: 'Disponible',
                getCellValue: (treatmentPlan) => {
                  return treatmentPlan.available ? 'Oui' : 'Non';
                }
              },
              {
                name: 'metadata',
                title: 'Metadata',
                getCellValue: (treatmentPlan) => {
                  if (!treatmentPlan.metadata) {
                    return 'NULL';
                  }
                  return (
                    <>
                      {Object.keys(treatmentPlan.metadata).map((key) => {
                        return (
                          <Typography key={key}>
                            {key} : {treatmentPlan.metadata[key]}
                          </Typography>
                        );
                      })}
                    </>
                  );
                }
              },
              {
                name: 'decision_practician',
                title: 'Décision praticien',
                getCellValue: (treatmentPlan) => {
                  if (!treatmentPlan.decision_practician_at) {
                    return 'Indisponible';
                  }

                  return `${
                    treatmentPlan.decision_practician ? 'Accepté' : 'Refusé'
                  } le ${format(
                    parseISO(treatmentPlan.decision_practician_at),
                    'dd/MM/yyyy - HH:mm'
                  )}`;
                }
              },
              {
                name: 'decision_patient',
                title: 'Décision patient',
                getCellValue: (treatmentPlan) => {
                  if (!treatmentPlan.decision_patient_at) {
                    return 'Indisponible';
                  }

                  return `${
                    treatmentPlan.decision_patient ? 'Accepté' : 'Refusé'
                  } le ${format(
                    parseISO(treatmentPlan.decision_patient_at),
                    'dd/MM/yyyy - HH:mm'
                  )}`;
                }
              }
            ]}
          >
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableHeaderRow />
            <Toolbar />
            <SearchPanel />
          </Grid>
        )}
      </>
    );
  }
}

export default TreatmentPlanUpload;
