import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import logo from '../assets/images/joovence-symbole.png';

const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    image: {
      width: 60,
      animation: `loading 0.6s ${theme.transitions.easing.easeInOut} infinite`,
      transformOrigin: 'center'
    },
    '@keyframes loading': {
      '0%, 100%': {
        opacity: 1,
        transform: `scale(1.1)`
      },
      '50%': {
        opacity: 0.7,
        transform: `scale(0.9)`
      }
    }
  };
};

const LoaderLogo = ({ classes }) => {
  return (
    <div className={classes.container}>
      <img className={classes.image} src={logo} alt="Joovence" />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(LoaderLogo);
