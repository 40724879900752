import React, { Component } from 'react';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements';

import classnames from 'classnames';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import axios from '../axios';

import Loadable from './Loadable';
import { Typography } from '../../node_modules/@material-ui/core';

const styles = (theme) => ({
  stripeElement: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  padded: {
    padding: theme.spacing(2)
  }
});

class CheckoutForm extends Component {
  state = {
    loading: false,
    success: false,
    error: null,
    charge: null
  };

  submit = async () => {
    const { patient, product, onSuccess, stripe } = this.props;

    const { token } = await stripe.createToken({
      name: patient.user.fullname
    });

    if (!token) {
      return;
    }

    this.setState({ loading: true });

    try {
      const response = await axios.get(`patient/${patient.id}/pay-${product}`, {
        params: {
          token: token.id
        }
      });

      if (response.data.error) {
        this.setState({ error: response.data.error });
      } else {
        this.setState({ success: true });

        if (onSuccess) {
          await onSuccess();
        }
      }

      this.setState({ loading: false });
    } catch (error) {
      if (error.response.data.error) {
        this.setState({ error: error.response.data.error });
      }
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes, alreadyPaid, price } = this.props;
    const { success, error, loading } = this.state;

    if (alreadyPaid || success) {
      return (
        <Typography className={classes.padded} color="primary">
          Le paiement a été effectué
        </Typography>
      );
    }

    const errorMessage = error ? (
      <Typography color="error">{error.message}</Typography>
    ) : null;

    return (
      <Loadable loading={loading} message="Paiement en cours">
        {errorMessage}
        <CardNumberElement
          className={classnames(classes.number, classes.stripeElement)}
        />
        <CardExpiryElement className={classes.stripeElement} />
        <CardCVCElement className={classes.stripeElement} />

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={this.submit}
        >
          Payer {price}€
        </Button>
      </Loadable>
    );
  }
}

export default withStyles(styles)(injectStripe(CheckoutForm));
