import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from './Dialog';

class DialogWithButton extends Component {
  state = {
    open: false,
    accepting: false
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  handleOpen = () => {
    this.setOpen(true);
  };

  handleClose = () => {
    this.setOpen(false);
  };

  handleAccept = async () => {
    const { onAccept } = this.props;
    this.setState({ accepting: true });
    if (onAccept) {
      await onAccept();
    }
    this.setOpen(false);
    this.setState({ accepting: false });
  };

  render() {
    const {
      buttonText,
      variant,
      color,
      title,
      description,
      cancelText,
      confirmText,
      className,
      children,
      fullWidth,
      buttonProps
    } = this.props;

    const { accepting, open } = this.state;

    return (
      <>
        <Button
          onClick={this.handleOpen}
          variant={variant || 'contained'}
          color={color || 'primary'}
          className={className}
          fullWidth={fullWidth}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
        >
          {buttonText}
        </Button>
        <Dialog
          open={open}
          onCancel={this.handleClose}
          onAccept={this.handleAccept}
          title={title}
          description={description}
          cancelText={cancelText}
          confirmText={confirmText}
          loading={accepting}
        >
          {children}
        </Dialog>
      </>
    );
  }
}

export default DialogWithButton;
