import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Upload from '../../../Upload';
import DownloadPatientSerie from '../../../DownloadPatientSerie';

const angles = [
  { name: 'profile_repose', display: 'Profile repose' },
  { name: 'frontal_repose', display: 'Frontal repose' },
  { name: 'frontal_smiling', display: 'Frontal smiling' },
  { name: 'occlussal_upper', display: 'Occlusal upper' },
  { name: 'occlussal_lower', display: 'Occlusal lower' },
  { name: 'buccal_right', display: 'Buccal right' },
  { name: 'labial_anterior', display: 'Labial anterior' },
  { name: 'buccal_left', display: 'Buccal left' }
];

function PhotosUpload({ patient }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Début de traitement</Typography>
          <DownloadPatientSerie patientId={patient.id} serie="check_up" />
        </Grid>
        {angles.map((angle) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={angle.name}>
              <Upload
                receiverId={patient.id}
                receiverType="patient"
                type="treatment_photo"
                meta={{
                  angle: angle.name,
                  serie: 'check_up'
                }}
                fileName={`photo-${angle.name}-check-up-${patient.reference}`}
                description={angle.display}
                retryable
                autoUpload
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">Fin de traitement</Typography>
        </Grid>
        {angles.map((angle) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={angle.name}>
              <Upload
                receiverId={patient.id}
                receiverType="patient"
                type="treatment_photo"
                meta={{
                  angle: angle.name,
                  serie: 'end_treatment'
                }}
                fileName={`photo-${angle.name}-end-treatment-${patient.reference}`}
                description={angle.display}
                retryable
                autoUpload
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default PhotosUpload;
