import React, { Component, Fragment } from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StripeApiKey from './StripeApiKey';
import axios from '../../axios';

import Loadable from '../Loadable';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing()
  }
});

class Balance extends Component {
  state = {
    practician: null,
    balance: null,
    hasNoApiKey: false,
    error: null,
    isFetching: false
  };

  componentDidMount() {
    this.fetchBalance();
  }

  fetchBalance = async () => {
    const { practicianId } = this.props;

    this.setState({ error: null, isFetching: true });

    try {
      const { data: balance } = await axios.get(
        `/practician/${practicianId}/balance`
      );

      this.setState({ balance, isFetching: false });
    } catch (error) {
      const {
        response: { data }
      } = error;

      if (data.error && data.error.code === 'no_api_key') {
        this.setState({ hasNoApiKey: true });
      }

      this.setState({ error: data.error, isFetching: false });
    }
  };

  render() {
    const { balance, hasNoApiKey, error, isFetching } = this.state;
    const { practicianId, classes } = this.props;

    if (hasNoApiKey) {
      return (
        <StripeApiKey
          practicianId={practicianId}
          onSuccess={() => {
            this.setState({ hasNoApiKey: false });
            this.fetchBalance();
          }}
        />
      );
    }

    return (
      <span className={classes.root}>
        <Typography color="textSecondary">Stripe balance</Typography>
        <Loadable loading={isFetching} noMessage size={20}>
          {balance && (
            <Typography>
              {balance.available[0].amount / 100}{' '}
              {balance.available[0].currency === 'eur'
                ? '€'
                : balance.available[0].currency}{' '}
              {balance.pending[0].amount && (
                <>
                  ({balance.pending[0].amount / 100}{' '}
                  {balance.pending[0].currency === 'eur'
                    ? '€'
                    : balance.pending[0].currency}{' '}
                  en attente)
                </>
              )}
            </Typography>
          )}
          {error && <Typography color="error">{error.message}</Typography>}
        </Loadable>
      </span>
    );
  }
}

export default withStyles(styles)(Balance);
