import React from 'react';

const TreatmentPlanInteractiveRenderer = ({ treatmentPlan }) => {
  if (!treatmentPlan) {
    return null;
  }

  const file = treatmentPlan.files[0];

  return (
    <iframe
      id="ttp_iframe"
      title="Plan de traitement 3D"
      width="100%"
      height="800px"
      frameBorder="none"
      src={`https://onyx.orthorobot.com/webviewer/main.html?mlink=${
        file.url
      }&bg=fff&p=${treatmentPlan.metadata && treatmentPlan.metadata.password}`}
    />
  );
};

export default TreatmentPlanInteractiveRenderer;
