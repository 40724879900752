import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { subYears } from 'date-fns';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { format } from '../../format';

import Header from '../Header';
import Form from './FormPractician';

import axios from '../../axios';

const styles = (theme) => ({
  wrapper: {
    padding: theme.spacing(2)
  }
});

const CreatePractician = ({ classes }) => {
  const [practician, setPractician] = useState(null);

  const handleSubmit = async (values) => {
    const { birthday, ...rest } = values;

    try {
      const { data } = await axios.post('/practician/create', {
        birthday: format(birthday, 'yyyy-MM-dd'),
        ...rest
      });

      setPractician(data);
      return undefined;
    } catch (error) {
      if (!error.response) {
        // eslint-disable-next-line no-console
        console.error(error);
        return undefined;
      }

      const { data } = error.response;

      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  if (practician) {
    return <Redirect to={`/practician/${practician.id}`} />;
  }

  const minDate = subYears(new Date(), 18);

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Praticiens', url: '/practician' },
          { name: 'Nouveau', url: '/practician/create' }
        ]}
      />

      <Paper className={classes.wrapper}>
        <Form
          initialValues={{
            birthday: minDate,
            price_booking: 90,
            price_treatment: 400,
            duration_booking: 30,
            availabilities_source: 'joovence'
          }}
          onSubmit={handleSubmit}
          submitText="Ajouter"
        />
      </Paper>
    </>
  );
};

export default withStyles(styles)(CreatePractician);
