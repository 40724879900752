import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Feed from '../Feed';

import PhotosUpload from './PhotosUpload';
import ScanUpload from './scanUpload/ScanUpload';
import TreatmentPlanUpload from './TreatmentPlanUpload';
import Confirmation from './Confirmation';
import Payment from './Payment';
import AlignersShipped from './AlignersShipped';
import Upload from '../../../Upload';

class Production extends Component {
  state = {
    refreshFeed: false
  };

  componentDidMount = () => {
    this.fetchPatient();
  };

  fetchPatient = () => {
    const { fetchPatient } = this.props;
    const { refreshFeed } = this.state;
    fetchPatient();
    this.setState({ refreshFeed: !refreshFeed });
  };

  render() {
    const { patient, match, snackbar } = this.props;

    const { refreshFeed } = this.state;

    return (
      <Feed
        patientId={patient.id}
        snackbar={snackbar}
        activePiece={match.params.piece}
        feedName="production"
        refresh={refreshFeed}
      >
        <Switch>
          <Route
            exact
            path="/patient/:id/production/photos_upload"
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <PhotosUpload {...props} patient={patient} />}
          />
          <Route
            exact
            path="/patient/:id/production/scan_upload"
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <ScanUpload {...props} patient={patient} />}
          />
          <Route
            exact
            path="/patient/:id/production/treatment_plan_confirmation_supplier"
            render={(props) => (
              <Confirmation
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                actor="supplier"
                patient={patient}
                fetchPatient={this.fetchPatient}
              />
            )}
          />
          <Route
            exact
            path="/patient/:id/production/treatment_plan_upload"
            render={(props) => (
              <TreatmentPlanUpload
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                patient={patient}
                fetchPatient={this.fetchPatient}
              />
            )}
          />
          <Route
            exact
            path="/patient/:id/production/treatment_plan_confirmation_practician"
            render={(props) => (
              <Confirmation
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                actor="practician"
                patient={patient}
                fetchPatient={this.fetchPatient}
              />
            )}
          />
          <Route
            exact
            path="/patient/:id/production/treatment_plan_confirmation_patient"
            render={(props) => (
              <Confirmation
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                actor="patient"
                patient={patient}
                fetchPatient={this.fetchPatient}
              />
            )}
          />
          <Route
            exact
            path="/patient/:id/production/payment"
            render={(props) => (
              <Payment
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                patient={patient}
                fetchPatient={this.fetchPatient}
              />
            )}
          />
          <Route
            exact
            path="/patient/:id/production/aligners_fabrication"
            render={() => (
              <Upload
                receiverId={patient.id}
                receiverType="patient"
                fileName={`lab-report-${patient.reference}`}
                type="treatment_document"
                description="Lab report"
                retryable
              />
            )}
          />
          <Route
            exact
            path="/patient/:id/production/aligners_shipped"
            render={(props) => (
              <AlignersShipped
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                patient={patient}
                fetchPatient={this.fetchPatient}
              />
            )}
          />
        </Switch>
      </Feed>
    );
  }
}

export default Production;
