import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import 'moment/locale/fr';

import { withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { baseV2 } from '../../axios';
import SelectPracticians from './SelectPracticians';
import Dialog from '../Dialog';

moment.locale('fr');

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const styles = (theme) => ({
  calendarContainer: {
    padding: theme.spacing(2),
    height: '1000px'
  },
  0: {
    background: '#ff7b00',
    color: 'black'
  },
  1: {
    background: '#9d4edd',
    color: 'white'
  },
  2: {
    background: '#006466',
    color: 'white'
  },
  3: {
    background: '#52b788',
    color: 'black'
  },
  4: {
    background: '#4f772d',
    color: 'white'
  },
  5: {
    background: '#0091ad',
    color: 'white'
  },
  6: {
    background: '#9d8189',
    color: 'white'
  },
  7: {
    background: '#7b2cbf',
    color: 'white'
  },
  8: {
    background: '#e2fdff',
    color: 'black'
  },
  9: {
    background: '#90f1ef',
    color: 'black'
  },
  10: {
    background: '#7fb800',
    color: 'white'
  }
});

const CalendarAvailabilities = ({ classes }) => {
  const [availabilitiesPerPractician, setAvailabilitiesPerPractician] =
    useState([]);
  const [practicians, setPracticians] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [open, setOpen] = useState(false);

  const fetchPracticians = async () => {
    try {
      const { data: fetchedPracticians } = await baseV2.get(`practicians`);

      setPracticians(fetchedPracticians);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPracticians();
  }, []);

  const fetchSelectedPracticiansAvailabilities = async (practicianId) => {
    try {
      const { data: fetchedAvailabilities } = await baseV2.get(
        `practicians/${practicianId}/availabilities`
      );

      return fetchedAvailabilities;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return undefined;
    }
  };

  const onSelectPracticians = async (_, value) => {
    // fetch all availabilities
    const fetchedAvailabilitiesPerPractician = await Promise.all(
      value.map(async (practician) => {
        const fetchedAvailabilities =
          await fetchSelectedPracticiansAvailabilities(practician.id);
        return { practician, availabilities: fetchedAvailabilities };
      })
    );

    setAvailabilitiesPerPractician(fetchedAvailabilitiesPerPractician);
  };

  const getEvents = () => {
    let availabilitiesEvents = [];
    let index = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const practicianAvailibities of availabilitiesPerPractician) {
      // eslint-disable-next-line no-restricted-syntax
      for (const availability of practicianAvailibities.availabilities) {
        // eslint-disable-next-line no-restricted-syntax
        for (const slot of availability.slots) {
          const startSlot = new Date(`${availability.date} ${slot}`);
          const endSlot = new Date(
            startSlot.getTime() +
              practicianAvailibities.practician.duration_booking * 60000
          );
          availabilitiesEvents = [
            ...availabilitiesEvents,
            {
              id: `${availability.date}-${slot}`,
              start: startSlot,
              end: endSlot,
              title: (
                <Typography color="inherit">
                  {`Dr. ${practicianAvailibities.practician.fullname}`}
                </Typography>
              ),
              draggable: false,
              data: practicianAvailibities,
              index
            }
          ];
        }
      }
      index += 1;
    }

    return availabilitiesEvents;
  };

  const handleSelect = (newEvent) => {
    setSelectedEvent(newEvent);
    setOpen(true);
  };

  const getClass = (event) => {
    return classes[event.index % 10];
  };

  return (
    <div className={classes.calendarContainer}>
      <Grid container justifyContent="flex-end">
        <Box pb={2}>
          <SelectPracticians
            practicians={practicians}
            onChange={onSelectPracticians}
          />
        </Box>
      </Grid>
      <DragAndDropCalendar
        // hide time range & showed practician's name instead
        formats={{
          eventTimeRangeFormat: () => ''
        }}
        localizer={localizer}
        events={getEvents()}
        drilldownView="agenda"
        defaultView={Views.MONTH}
        onSelectEvent={handleSelect}
        step={15}
        eventPropGetter={(event) => ({
          className: getClass(event)
        })}
      />

      {selectedEvent && (
        <Dialog
          open={open}
          title={`Dr. ${selectedEvent.data.practician.fullname} - ${selectedEvent.data.practician.alias}`}
          onAccept={() => {
            setOpen(false);
          }}
          confirmText="Fermer"
        >
          <>
            <Box textAlign="center" pb={2}>
              <Typography variant="caption" color="textSecondary">
                Slot
              </Typography>
              <Typography>{`${moment(selectedEvent.start).format(
                'DD/MM'
              )} ${moment(selectedEvent.start).format('HH:mm')} - ${moment(
                selectedEvent.end
              ).format('HH:mm')}`}</Typography>
            </Box>

            <Box textAlign="center" pb={2}>
              <Typography variant="caption" color="textSecondary">
                Address
              </Typography>
              <Typography>{`${selectedEvent.data.practician.address.address}`}</Typography>
              <Typography>{`${selectedEvent.data.practician.address.city} ${selectedEvent.data.practician.address.postal_code}`}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="caption" color="textSecondary">
                Agenda
              </Typography>
              <Typography>
                {selectedEvent.data.practician.availabilities_source}
              </Typography>
            </Box>
          </>
        </Dialog>
      )}
    </div>
  );
};

export default withStyles(styles)(CalendarAvailabilities);
