import React, { Component } from 'react';
import Form from './FormPiece';
import axios from '../../../axios';

class EditPiece extends Component {
  handleSubmit = async (values) => {
    try {
      await this.editPiece(values);
      return undefined;
    } catch ({ response: { data } }) {
      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  editPiece = async (values) => {
    const { feedId, fetchPieces, piece, onCancel, snackbar } = this.props;

    await axios.put(`/piece/${piece.id}`, values);

    fetchPieces(feedId);

    snackbar.open('La pièce a bien été modifiée', 'success');
    onCancel();
  };

  render() {
    const {
      piece: { order, name, display, action_required },
      onCancel
    } = this.props;

    return (
      <Form
        initialValues={{
          order,
          name,
          display,
          action_required
        }}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        submitText="Enregistrer"
      />
    );
  }
}

export default EditPiece;
