import { makeStyles } from '@material-ui/core';

import { green, amber, blue, red } from '@material-ui/core/colors';

export const useCustomStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 4)
  },
  header: {
    fontSize: 24,
    fontWeight: 400
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    margin: theme.spacing(2, 0)
  },
  tag: {
    margin: theme.spacing(0, 1, 1, 0),

    color: theme.palette.common.white
  },
  dropdown: {
    height: 32,

    margin: theme.spacing(0, 1, 1, 0),
    padding: theme.spacing(0, 1.5),

    backgroundColor: `${theme.palette.grey.A700} !important`,
    color: `${theme.palette.common.white} !important`,
    borderRadius: 16,
    fontSize: 14,

    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  dropdownIcon: {
    marginRight: theme.spacing(1),

    color: theme.palette.common.white
  },
  dropdownMenu: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(-1)
  },
  placeholder: {
    display: 'none'
  },
  dropdownList: {
    padding: 0,

    '& li': {
      color: theme.palette.common.white,

      '&:hover': {
        color: theme.palette.common.black
      }
    }
  },
  tagTypeSuccess: {
    backgroundColor: green[500]
  },
  tagTypeWarning: {
    backgroundColor: amber[700]
  },
  tagTypeInfo: {
    backgroundColor: blue[500]
  },
  tagTypeDanger: {
    backgroundColor: red[500]
  }
}));
