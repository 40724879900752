import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import moment from 'moment';
import 'moment/locale/fr';

import { endOfMonth, startOfMonth } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '../Dialog';
import axios from '../../axios';
import { formatDateTime } from '../../format';

moment.locale('fr');

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const styles = (theme) => ({
  root: {
    height: '100%'
  },
  calendarContainer: {
    padding: theme.spacing(2),
    height: '1000px'
  },
  availability: {
    background: theme.palette.primary.main,
    color: 'white'
  },
  booking: {
    background: theme.palette.secondary.light,
    color: 'white'
  },
  booking_other: {
    background: '#5CAAAE',
    color: 'white'
  }
});

class Agenda extends Component {
  state = {
    availabilities: [],
    bookings: [],
    selectedEvent: null,
    open: false,
    openAdd: false,
    toAdd: null,
    showAvailabilities: false,
    showBookings: true,
    range: {
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date())
    }
  };

  componentDidMount = async () => {
    this.fetchEvents(false);
  };

  fetchEvents = async (notification = true) => {
    const { snackbar } = this.props;
    await Promise.all([this.fetchAvailabilities(), this.fetchBookings()]);

    if (notification) {
      snackbar.open('Agenda à jour', 'success');
    }
  };

  fetchAvailabilities = async () => {
    try {
      const { data: availabilities } = await axios.get(`availabilities`);

      this.setState({ availabilities });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  fetchBookings = async () => {
    const { range } = this.state;

    const minDate = range.start;
    const maxDate = range.end;

    try {
      const { data: bookings } = await axios.get(`bookings`, {
        params: {
          min_date: formatDateTime(minDate),
          max_date: formatDateTime(maxDate)
        }
      });

      this.setState({ bookings });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  onRangeChange = (newRange) => {
    let formattedRange = newRange;

    if (Array.isArray(newRange)) {
      formattedRange = {
        start: newRange[0],
        end: newRange[newRange.length - 1]
      };
    }

    this.setState({ range: formattedRange }, () => {
      this.fetchBookings();
    });
  };

  getEvents = () => {
    const { availabilities, bookings, showAvailabilities, showBookings } =
      this.state;

    const availabilitiesEvents =
      (showAvailabilities &&
        availabilities &&
        availabilities.map((a) => ({
          id: a.id,
          start: new Date(a.start),
          end: new Date(a.end),
          title: (
            <Typography color="inherit">{a.practician.fullname}</Typography>
          ),
          draggable: false,
          type: 'availability',
          data: a
        }))) ||
      [];

    const bookingsEvents =
      (showBookings &&
        bookings.length &&
        bookings
          .filter((b) => b.practician_id)
          .map((b) => ({
            id: b.id,
            start: new Date(b.date),
            end: moment(b.date).add(30, 'minutes').toDate(),
            title: (
              <>
                <Typography
                  color="inherit"
                  variant="caption"
                  component="p"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {b.status === 'done' && <>✅</>}
                  {b.patient.fullname}
                </Typography>
                {b.practician && (
                  <Typography color="inherit" variant="caption" component="p">
                    Dr. {b.practician.fullname}
                  </Typography>
                )}
              </>
            ),
            draggable: false,
            type: 'booking',
            data: b
          }))) ||
      [];

    return [...bookingsEvents, ...availabilitiesEvents];
  };

  handleSelect = (selectedEvent) => {
    this.setState({ selectedEvent, open: true });
  };

  getClass = (type, data) => {
    const { classes } = this.props;
    if (type !== 'booking') return classes.availability;

    if (data.type === 'pre_treatment') return classes.booking;
    return classes.booking_other;
  };

  render() {
    const { classes } = this.props;
    const { selectedEvent, open, showAvailabilities, showBookings } =
      this.state;

    const checkboxes = (
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={showAvailabilities}
              onChange={() =>
                this.setState({ showAvailabilities: !showAvailabilities })
              }
              color="primary"
            />
          }
          label="Disponibilités"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showBookings}
              onChange={() => this.setState({ showBookings: !showBookings })}
            />
          }
          label="Consultations"
        />
      </FormGroup>
    );

    return (
      <div className={classes.calendarContainer}>
        <Button variant="contained" color="primary" onClick={this.fetchEvents}>
          Recharger
        </Button>
        {checkboxes}
        <DragAndDropCalendar
          formats={{
            eventTimeRangeFormat: () => {
              return '';
            }
          }}
          localizer={localizer}
          events={this.getEvents()}
          drilldownView="week"
          min={new Date(2018, 1, 1, 7, 0, 0)}
          max={new Date(2018, 1, 1, 22, 0, 0)}
          defaultView={Views.MONTH}
          onSelectEvent={this.handleSelect}
          step={15}
          onRangeChange={this.onRangeChange}
          eventPropGetter={(e) => ({
            className: this.getClass(e.type, e.data)
          })}
        />

        {selectedEvent && (
          <Dialog
            open={open}
            title={
              selectedEvent.type === 'booking'
                ? 'Consultation'
                : 'Disponibilité'
            }
            description={
              selectedEvent.type === 'booking' ? (
                <>
                  <Typography>
                    RDV de{' '}
                    <Link to={`/patient/${selectedEvent.data.patient.id}`}>
                      {selectedEvent.data.patient.fullname}
                    </Link>{' '}
                    le {moment(selectedEvent.start).format('DD/MM')} à{' '}
                    {moment(selectedEvent.start).format('HH:mm')} avec{' '}
                    <Link
                      to={`/practician/${selectedEvent.data.practician.id}`}
                    >
                      Dr. {selectedEvent.data.practician.fullname}
                    </Link>
                  </Typography>
                </>
              ) : (
                <Typography>
                  Disponibilité le {moment(selectedEvent.start).format('DD/MM')}{' '}
                  {moment(selectedEvent.start).format('HH:mm')} -{' '}
                  {moment(selectedEvent.end).format('HH:mm')} du{' '}
                  <Link to={`/practician/${selectedEvent.data.practician.id}`}>
                    Dr. {selectedEvent.data.practician.fullname}
                  </Link>
                </Typography>
              )
            }
            onAccept={() => {
              this.setState({ open: false });
            }}
            confirmText="Fermer"
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Agenda);
