import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './dashboard/Main';
import Patients from './patients/Main';
import Practicians from './practicians/Main';
import Bookings from './bookings/Main';
import Settings from './settings/Main';
import CalendarAvailabilities from './availabilities/CalendarAvailabilities';

function Content() {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/(patient|patients)" component={Patients} />
      <Route path="/(practician|practicians)" component={Practicians} />
      <Route path="/(booking|bookings)" component={Bookings} />
      <Route path="/availabilities" component={CalendarAvailabilities} />
      <Route path="/settings" component={Settings} />
    </Switch>
  );
}

export default Content;
