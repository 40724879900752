import { Grid } from '@material-ui/core';
import format from 'date-fns/format';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { baseV2 } from '../../../axios';
import Dialog from '../../Dialog';
import MyCheckbox from '../../final-form/Checkbox';
import { occurenceIsExcluded } from './helper';

export const DeleteRecurrentAvailability = ({
  event,
  snackbar,
  onDelete,
  onCancel
}) => {
  const isExcluded = occurenceIsExcluded(event.data, event.start);

  const deleteRecurrentAvailability = async () => {
    try {
      await baseV2.delete(`recurrent-availabilities/${event.id}`);

      snackbar.open(
        'La disponibilité récurrente a bien été ajoutée',
        'success'
      );
    } catch (error) {
      snackbar.open(
        "La disponibilité récurrente n'a pas pu être supprimée",
        'error'
      );
    } finally {
      await onDelete();
    }
  };

  const addOccurrenceToExcludedDates = (chosenDate, excludedDates) => {
    excludedDates.push(chosenDate);
    return excludedDates;
  };

  const removeOccurrenceFromExcludedDates = (chosenDate, excludedDates) => {
    return excludedDates.filter((date) => date !== chosenDate);
  };

  const handleOccurrence = async (values) => {
    const occurenceDate = format(event.start, 'yyyy-MM-dd');
    const { excluded_dates: currentExcludedDates } = event.data;

    let newExcludedDates;
    if (isExcluded) {
      newExcludedDates = removeOccurrenceFromExcludedDates(
        occurenceDate,
        currentExcludedDates
      );
    } else {
      newExcludedDates = addOccurrenceToExcludedDates(
        occurenceDate,
        currentExcludedDates
      );
    }

    try {
      await baseV2.put(`recurrent-availabilities/${event.id}`, {
        excluded_dates: newExcludedDates
      });

      if (values.occurence_only) {
        snackbar.open('Cette occurrence a été supprimée', 'success');
      } else {
        snackbar.open('Cette occurrence a été restaurée', 'success');
      }
    } catch (error) {
      if (values.occurence_only) {
        snackbar.open("Cette occurrence n'a pas pu être supprimée", 'error');
      } else {
        snackbar.open("Cette occurrence n'a pas pu être restaurée", 'error');
      }
    } finally {
      await onDelete();
    }
  };

  const handleRecurrentAvailability = (values) => {
    if (values.occurence_only || isExcluded) {
      handleOccurrence(values);
    } else {
      deleteRecurrentAvailability();
    }
  };

  if (!event) return null;
  return (
    <Form
      onSubmit={handleRecurrentAvailability}
      render={({ handleSubmit }) => (
        <Dialog
          open
          title={
            isExcluded
              ? 'Rétablir cette occurrence'
              : 'Supprimez cette occurrence'
          }
          description={`Voulez-vous supprimer votre disponibilité du ${moment(
            event.start
          ).format('DD/MM')} ${moment(event.start).format('HH:mm')} - ${moment(
            event.end
          ).format('HH:mm')}`}
          onCancel={onCancel}
          onAccept={handleSubmit}
          cancelText="Cancel"
          confirmText={isExcluded ? 'Restore' : 'Delete'}
        >
          <form onSubmit={handleSubmit} id="recurrent-availability-delete">
            <Grid container>
              {!isExcluded && (
                <Grid item xs={12}>
                  <Field
                    type="checkbox"
                    name="occurence_only"
                    label="Seulement cette occurence"
                    component={MyCheckbox}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Dialog>
      )}
    />
  );
};
