import React from 'react';

import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import TextField from '../final-form/TextField';
import axios from '../../axios';

export default ({ practicianId, onSuccess }) => {
  const onSubmit = async (values) => {
    try {
      await axios.put(`practician/${practicianId}/stripe-api-key`, values);
      if (onSuccess) {
        onSuccess();
      }
      return undefined;
    } catch (error) {
      if (error.response.data.error) {
        return {
          stripe_api_key: error.response.data.error.message
        };
      }

      return {
        stripe_api_key: "Impossible d'ajouter la clé d'API"
      };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="stripe_api_key"
            label="Clé d'API Stripe"
            component={TextField}
          />
          <Button variant="contained" color="primary" type="submit">
            Envoyer
          </Button>
        </form>
      )}
    />
  );
};
