import React from 'react';

import { Typography } from '@material-ui/core';
import parseISO from 'date-fns/parseISO';

import { format } from '../../../../format';

const AlignersShipped = ({ patient }) => {
  if (patient.shipped_at) {
    return (
      <>
        <Typography>
          Shipped on {format(parseISO(patient.shipped_at), 'dd/MM/yyyy HH:mm')}
        </Typography>
        <Typography
          component="a"
          href={patient.shipping_tracking}
          target="_blank"
        >
          Tracking
        </Typography>
      </>
    );
  }

  return <Typography>Not yet shipped</Typography>;
};

export default AlignersShipped;
