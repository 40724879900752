import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  sidebarWidth: 240,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        background:
          'linear-gradient(19deg, #FAACA8 0%, rgba(250,172,168,0.7) 50%, #FAACA8 100%)'
      }
    }
  }
});
