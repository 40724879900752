import React, { Component, Fragment } from 'react';

import PlacesAutocomplete from 'react-places-autocomplete';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { Field } from 'react-final-form';
import TextField from './TextField';
import Loadable from '../Loadable';

const searchOptions = {
  // types: ["geocode"]
  // componentRestrictions: { country: "fr" }
};

class Places extends Component {
  state = {
    noResults: false
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ noResults: false });
    }
  }

  handleError = (error) => {
    if (error === 'ZERO_RESULTS') {
      this.setState({ noResults: true });
    }
  };

  render() {
    const { value, name, label, onChange, onSelect, fullWidth } = this.props;
    const { noResults } = this.state;

    return (
      <PlacesAutocomplete
        value={value}
        searchOptions={searchOptions}
        onChange={onChange}
        onSelect={onSelect}
        onError={this.handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <>
              <Field
                name={name}
                label={label}
                render={(props) => {
                  return (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...getInputProps()}
                      fullWidth={fullWidth}
                    />
                  );
                }}
              />
              <Paper square>
                <Loadable loading={loading}>
                  {noResults ? (
                    <MenuItem component="div">Aucun résultat</MenuItem>
                  ) : (
                    suggestions
                      // .filter(suggestion => {
                      //   return (
                      //     suggestion.types.includes("street_address") ||
                      //     suggestion.types.includes("geocode")
                      //   );
                      // })
                      .map((suggestion) => (
                        <MenuItem
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...getSuggestionItemProps(suggestion)}
                          component="div"
                        >
                          {suggestion.description}
                        </MenuItem>
                      ))
                  )}
                </Loadable>
              </Paper>
            </>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default Places;
