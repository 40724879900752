import React from 'react';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import Upload from './Upload';

import { getAnglesFromVersion } from '../utils/photos.utils';

const styles = (theme) => ({
  submit: {
    marginTop: theme.spacing(2)
  },
  icon: {
    marginLeft: theme.spacing()
  },
  hint: {
    width: '100%'
  }
});

const PhotosUpload = ({
  serie,
  version,
  patient,

  readOnly,
  onSuccess
}) => {
  const angles = getAnglesFromVersion(version);
  return (
    <Grid container spacing={3} justify="center">
      {angles.map((angle) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={angle.name}>
          <Upload
            receiverId={patient.id}
            receiverType="patient"
            type="treatment_photo"
            backgroundImage={angle.background}
            meta={{
              angle: angle.name,
              serie
            }}
            fileName={`photo-${angle.name}-${serie}-${patient.reference}`}
            description={angle.display}
            retryable
            autoUpload
            onSuccess={(file) => {
              if (onSuccess) {
                onSuccess(file);
              }
            }}
            readOnly={readOnly}
            // hint={
            //   <img
            //     alt={angle.display}
            //     className={classes.hint}
            //     src={angle.hint}
            //   />
            // }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default withStyles(styles)(PhotosUpload);
