import axios, { axiosWithoutCredentials } from '../axios';

export const uploadToS3 = async (file, options = {}) => {
  const extension = file.name.split('.').pop();
  const response = await axios.post(
    '/vapor/signed-storage-url',
    {
      bucket: options.bucket || '',
      content_type: options.contentType || file.type,
      expires: options.expires || '',
      visibility: options.visibility || '',
      metadata: {
        'client-extension': extension,
        ...(options.metadata || {})
      }
    },
    {
      baseURL: options.baseURL || process.env.REACT_APP_API_URL,
      headers: options.headers || {},
      ...options.options
    }
  );

  const { headers } = response.data;

  if ('Host' in headers) {
    delete headers.Host;
  }

  if (typeof options.progress === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    options.progress = () => {};
  }

  const cancelToken = options.cancelToken || '';

  await axiosWithoutCredentials.put(response.data.url, file, {
    cancelToken,
    headers,
    onUploadProgress: (progressEvent) => {
      options.progress(progressEvent.loaded / progressEvent.total);
    }
  });

  response.data.extension = extension;

  return response.data;
};
