import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Upload from '../../../../Upload';
import ScanSerieUpload from './ScanSerieUpload';

function UploadScan({ patient }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">X-ray</Typography>
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <Upload
          receiverId={patient.id}
          receiverType="patient"
          fileName={`xray-${patient.reference}`}
          type="treatment_xray"
          description="Radio"
          retryable
        />
      </Grid>
      <ScanSerieUpload patient={patient} serie="pre_treatment" />
      <ScanSerieUpload patient={patient} serie="mid_treatment" />
      <ScanSerieUpload patient={patient} serie="end_treatment" />
      <ScanSerieUpload patient={patient} serie="other" />
    </Grid>
  );
}

export default UploadScan;
