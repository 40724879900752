import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Authenticated from './Authenticated';
import LoadingPage from './LoadingPage';
import RedirectToAuth from './RedirectToAuth';

import axios, { baseV2 } from '../axios';

class Layout extends Component {
  state = {
    user: null,
    fetchedUser: false,
    redirectToAuth: false
  };

  async componentDidMount() {
    await this.fetchUser();
  }

  fetchUser = async () => {
    try {
      const { data } = await axios.get(`/auth/user`);

      this.setState({ user: data });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      const { user } = this.state;
      this.setState({ fetchedUser: true }, () => {
        if (!user) {
          this.setState({ redirectToAuth: true });
        }
      });
    }
  };

  logout = async () => {
    // eslint-disable-next-line no-console
    console.log('here');
    try {
      await baseV2.get('/auth/logout');
      this.setState({ redirectToAuth: true });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  render() {
    const { user, redirectToAuth } = this.state;

    if (redirectToAuth) {
      return <RedirectToAuth />;
    }

    return (
      <Route
        path="/"
        render={(props) =>
          user ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Authenticated {...props} logout={this.logout} />
          ) : (
            <LoadingPage />
          )
        }
      />
    );
  }
}

export default Layout;
