import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Checkout from '../../../Checkout';

function Payment({ patient: { booking }, fetchPatient, patient }) {
  return (
    <>
      <Grid item sm={4}>
        <Checkout
          product="booking"
          price={booking.price}
          patient={patient}
          alreadyPaid={booking.paid}
          onSuccess={fetchPatient}
        />
      </Grid>
      <Grid item sm={4}>
        <Typography color="textSecondary" gutterBottom>
          Statut
        </Typography>
        <Typography>
          {booking.paid ? 'Payé' : 'Non payé'} ({booking.formatted_price})
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Prix
        </Typography>
        <Typography>{booking.formatted_price}</Typography>
      </Grid>
    </>
  );
}

export default Payment;
