import React, { Component } from 'react';
import Form from './FormFeed';
import axios from '../../../axios';

class Edit extends Component {
  handleSubmit = async (values) => {
    try {
      await this.editFeed(values);
      return undefined;
    } catch ({ response: { data } }) {
      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  editFeed = async (values) => {
    const { feedId, fetchFeeds, feed, onCancel, snackbar } = this.props;

    await axios.put(`/feed/${feed.id}`, values);

    fetchFeeds(feedId);
    snackbar.open('Le feed a bien été modifié', 'success');
    onCancel();
  };

  render() {
    const { feed, onCancel } = this.props;

    return (
      <Form
        initialValues={{
          name: feed.name,
          display: feed.display
        }}
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        submitText="Enregistrer"
      />
    );
  }
}

export default Edit;
