import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

import { Link } from 'react-router-dom';

import {
  DataTypeProvider,
  SearchState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';

import {
  Grid as GridDX,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';

import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import { parseISO } from 'date-fns';
import Header from '../../Header';

import { v2 } from '../../../axios';
import { format } from '../../../format';

import Loadable from '../../Loadable';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  formContainer: {
    padding: theme.spacing(2)
  }
});

const statusList = [
  { name: 'booking', display: 'RDV' },
  { name: 'production', display: 'Production' },
  { name: 'shipping', display: 'Livraison' },
  { name: 'treatment', display: 'Traitement' },
  { name: 'done', display: 'Terminé' },
  { name: 'abandoned', display: 'Abandonné' },
  { name: 'missing-payment', display: 'Paiement manquant' }
];

const getStatus = (status) => {
  return statusList.find((s) => s.name === status);
};

const LinkFormatter = ({ row, value, column }) => {
  let { id } = row;

  if (column.name === 'practician') {
    id =
      (row.practician && row.practician.id) ||
      (row.booking && row.booking.practician && row.booking.practician.id);
  }

  return <Link to={`/${column.name}/${id}`}>{value}</Link>;
};

const LinkTypeProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DataTypeProvider formatterComponent={LinkFormatter} {...props} />
);

const FeedFormatter = ({ row: patient, value: feed }) => {
  if (patient.status === 'treatment') {
    return (
      <Tooltip
        title={
          <>
            <Typography color="inherit">
              Début :{' '}
              {format(parseISO(patient.treatment_started_at), 'dd/MM/yyyy')}
            </Typography>
            <Typography color="inherit">
              Progression : {patient.progression_aligners}%
            </Typography>
            <Typography color="inherit">
              Progression théorique : {patient.theoric_progression_aligners}%
            </Typography>
          </>
        }
      >
        <Typography
          to={`/patient/${patient.id}/${patient.status}/${patient.actual_aligners.serie}`}
          component={Link}
          color={
            patient.theoric_progression_aligners > patient.progression_aligners
              ? 'error'
              : 'initial'
          }
        >
          {patient.actual_aligners.serie} / {patient.nb_aligners} (
          {patient.progression_aligners}% -{' '}
          {patient.theoric_progression_aligners}%)
        </Typography>
      </Tooltip>
    );
  }

  if (!feed) {
    return '';
  }

  return (
    <Typography
      to={`/patient/${patient.id}/${patient.status}/${feed.name}`}
      component={Link}
      color={feed.status === 'on-going' ? 'secondary' : 'initial'}
    >
      {feed.display}
    </Typography>
  );
};

const FeedTypeProvider = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DataTypeProvider formatterComponent={FeedFormatter} {...props} />;
};

class PatientsList extends Component {
  state = {
    patients: [],
    error: null,
    isFetching: false,
    status: ['production']
  };

  async componentDidMount() {
    try {
      this.fetchPatients();
    } catch (error) {
      this.setState({
        error: 'Erreur lors de la récuperation des patients.',
        isFetching: false
      });
    }
  }

  componentDidUpdate = async (_prevProps, prevState) => {
    const { status } = this.state;
    if (prevState.status.length !== status.length) {
      await this.fetchPatients();
    }
  };

  fetchPatients = async () => {
    this.setState({ isFetching: true });
    let { status } = this.state;

    if (!status.length) {
      status = ['booking', 'production', 'shipping', 'treatment', 'done'];
    }

    let response = null;

    if (status.includes('missing-payment')) {
      response = await v2.get('/patients/missing-payment');
    } else {
      response = await v2.get('/patients', {
        params: {
          status
        }
      });
    }

    this.setState({ patients: response.data, isFetching: false });
  };

  handleChangeStatus = (event) => {
    this.setState({ status: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { patients, isFetching, status: stateStatus } = this.state;

    const tableColumnExtensions = [
      { columnName: 'patient' },
      { columnName: 'practician' },
      { columnName: 'booking' },
      { columnName: 'production' }
    ];

    return (
      <>
        <Header breadcrumbs={[{ name: 'Patients', url: '/patient' }]} />
        <Paper className={classes.root}>
          <div className={classes.formContainer}>
            <Grid container justify="flex-end">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="select-multiple-chip">Statut</InputLabel>
                  <Select
                    multiple
                    value={stateStatus}
                    onChange={this.handleChangeStatus}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => {
                          return (
                            <Chip
                              key={value}
                              label={getStatus(value)?.display}
                              className={classes.chip}
                            />
                          );
                        })}
                      </div>
                    )}
                  >
                    {statusList.map((s) => (
                      <MenuItem key={s.name} value={s.name}>
                        {s.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <Loadable loading={isFetching}>
            <GridDX
              rows={patients}
              columns={[
                {
                  title: 'Patient',
                  name: 'patient',
                  getCellValue: (p) => {
                    return p.fullname;
                  }
                },
                {
                  title: 'Practician',
                  name: 'practician',
                  getCellValue: (p) => {
                    return (
                      (p.practician && p.practician.fullname) ||
                      (p.booking &&
                        p.booking.practician &&
                        `${p.booking.practician.fullname} (RDV)`) ||
                      ''
                    );
                  }
                },
                {
                  name: 'status',
                  title: 'Status',
                  getCellValue: (p) => {
                    const status = getStatus(p.status);

                    if (!status) return '';
                    let { display } = status;

                    if (p.status === 'booking' && p.booking) {
                      display = `${display} le ${format(
                        parseISO(p.booking.date),
                        'd MMMM'
                      )} ${p.booking.status === 'canceled' ? '(annulé)' : ''}`;
                    }

                    return display;
                  }
                },
                {
                  name: 'feed',
                  title: 'Étape',
                  getCellValue: (p) => {
                    const status = getStatus(p.status);

                    if (!status) return '';
                    let { name } = status;

                    // new bookings
                    if (name === 'booking') name = 'booking_no_payment';

                    const feed =
                      (p.feeds && p.feeds.find((f) => f.name === name)) || null;

                    return (feed && feed.pieces[0] && feed.pieces[0]) || null;
                  }
                }
              ]}
            >
              <SearchState defaultValue="" />
              <IntegratedFiltering />
              <LinkTypeProvider for={['patient']} />
              <LinkTypeProvider for={['practician']} />
              <FeedTypeProvider for={['feed']} />
              <Table columnExtensions={tableColumnExtensions} />
              <TableHeaderRow />
              <Toolbar />
              <SearchPanel />
            </GridDX>
          </Loadable>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(PatientsList);
