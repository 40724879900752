import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import Loadable from '../Loadable';
import axios from '../../axios';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  }
});

class Bookings extends Component {
  state = {
    bookings: null,
    isFetching: false
  };

  componentDidMount() {
    this.fetchBookings();
  }

  fetchBookings = async () => {
    try {
      this.setState({ isFetching: true });
      const { data: bookings } = await axios.get('dashboard/bookings');
      this.setState({ bookings, isFetching: false });
    } catch (error) {
      this.setState({ isFetching: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { bookings, isFetching } = this.state;

    const counts = bookings && (
      <Grid container spacing={3}>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            En attente de photos
          </Typography>
          <Typography variant="h5" align="center">
            {bookings.photos}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            En attente de confirmation
          </Typography>
          <Typography variant="h5" align="center">
            {bookings.decision_supplier}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            Création dossier fournisseur
          </Typography>
          <Typography variant="h5" align="center">
            {bookings.supplier_case}
          </Typography>
        </Grid>
        <Grid item sm={3} xs={6}>
          <Typography color="textSecondary" align="center">
            En attente de consultation
          </Typography>
          <Typography variant="h5" align="center">
            {bookings.confirmed}
          </Typography>
        </Grid>
      </Grid>
    );

    return (
      <Paper className={classes.root}>
        <Typography variant="subtitle1">Rendez-vous</Typography>
        <Loadable loading={isFetching}>{counts}</Loadable>
      </Paper>
    );
  }
}

export default withStyles(styles)(Bookings);
