import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Upload from '../../../../Upload';

const ScanSerieUpload = ({ patient, serie }) => {
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Scan - {serie}</Typography>
      </Grid>
      <Grid item sm={12} md={6}>
        <Upload
          receiverId={patient.id}
          receiverType="patient"
          fileName={`scan-upper-${patient.reference}`}
          type="treatment_scan"
          description="Scan dentaire maxillaire"
          retryable
          meta={{
            angle: 'upper',
            serie
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Upload
          receiverId={patient.id}
          receiverType="patient"
          fileName={`scan-lower-${patient.reference}`}
          type="treatment_scan"
          description="Scan dentaire mandibulaire"
          retryable
          meta={{
            angle: 'lower',
            serie
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Upload
          receiverId={patient.id}
          receiverType="patient"
          fileName={`scan-occlusion-${patient.reference}`}
          type="treatment_scan"
          description="Scan dentaire occlusion"
          retryable
          meta={{
            angle: 'occlusion',
            serie
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Upload
          receiverId={patient.id}
          receiverType="patient"
          fileName={`scan-occlusion-additional-${patient.reference}`}
          type="treatment_scan"
          description="Scan dentaire occlusion (supplémentaire)"
          retryable
          meta={{
            angle: 'occlusion_additional',
            serie
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ScanSerieUpload;
