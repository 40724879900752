import React, { Component, Fragment } from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Header from '../Header';
import DialogWithButton from '../DialogWithButton';

import Informations from './view/Informations';
import Tabs from './view/Tabs';
import Agenda from './view/Agenda';
import Bookings from './view/Bookings';
import Patients from './view/Patients';
import Charges from './view/Charges';

import axios from '../../axios';

const styles = (theme) => ({
  practicianWrapper: {
    padding: theme.spacing()
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
});

class View extends Component {
  state = {
    practician: null,
    // eslint-disable-next-line react/destructuring-assignment
    tab: this.props.match.params.tab || 'agenda'
  };

  fetchPractician = async () => {
    const { match } = this.props;

    const response = await axios.get(`/practician/${match.params.id}`);

    this.setState({ practician: response.data });
  };

  componentDidMount = async () => {
    await this.fetchPractician();
  };

  changeTab = (tab) => {
    this.setState({ tab });
  };

  loginAs = async () => {
    const { practician } = this.state;
    try {
      const { data: url } = await axios.get(
        `/practician/${practician.user_id}/login-as`
      );
      window.location.replace(url);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  toggleStatus = async () => {
    const { match } = this.props;
    const { practician } = this.state;

    await axios.put(`/practician/${match.params.id}/change-status`, {
      status: practician.status === 'active' ? 'inactive' : 'active'
    });

    await this.fetchPractician();
  };

  render = () => {
    const { classes, snackbar } = this.props;
    const { practician, tab } = this.state;

    if (!practician) {
      return null;
    }

    const {
      user: { addresses },
      user: infos
    } = practician;

    return (
      <>
        <Header
          breadcrumbs={[
            { name: 'Praticiens', url: '/practicians' },
            {
              name: `Dr. ${infos.fullname}`,
              url: `/practician/${practician.id}`
            }
          ]}
        />

        <Card className={classes.practicianWrapper} align="center">
          <CardHeader
            action={
              <>
                <DialogWithButton
                  buttonText={
                    practician.status === 'active' ? 'Désactiver' : 'Activer'
                  }
                  title={`${
                    practician.status === 'active' ? 'Désactiver' : 'Activer'
                  } ce praticien`}
                  description={`Voulez-vous ${
                    practician.status === 'active' ? 'désactiver' : 'activer'
                  } ce praticien?`}
                  onAccept={this.toggleStatus}
                />

                <IconButton
                  aria-label="add"
                  to={`/practician/${practician.id}/edit`}
                  component={Link}
                >
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="login-as" onClick={this.loginAs}>
                  <ExitToAppIcon />
                </IconButton>
              </>
            }
          />
          <Informations
            infos={infos}
            practician={practician}
            addresses={addresses}
          />
        </Card>

        <Paper className={classes.paper}>
          <Tabs
            url={`/practician/${practician.id}`}
            changeTab={this.changeTab}
            value={tab}
          />

          <Switch>
            <Route
              path="/practician/:id/agenda"
              render={(props) => (
                <Agenda
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  fetchPractician={this.fetchPractician}
                  practician={practician}
                />
              )}
            />
            <Route
              path="/practician/:id/bookings"
              render={(props) => (
                <Bookings
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  fetchPractician={this.fetchPractician}
                  practician={practician}
                />
              )}
            />
            <Route
              path="/practician/:id/patients"
              render={(props) => (
                <Patients
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  fetchPractician={this.fetchPractician}
                  practician={practician}
                />
              )}
            />
            <Route
              path="/practician/:id/charges"
              render={(props) => (
                <Charges
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  fetchPractician={this.fetchPractician}
                  practician={practician}
                />
              )}
            />
          </Switch>
        </Paper>
      </>
    );
  };
}

export default withStyles(styles)(View);
