import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Form, Field } from 'react-final-form';

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '../../final-form/TextField';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing()
  },
  rightIcon: {
    marginLeft: theme.spacing()
  },
  input: {
    marginRight: theme.spacing()
  }
});

const FormFeed = ({
  onSubmit,
  onCancel,
  classes,
  submitText,
  initialValues
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field fullWidth name="name" label="Nom" component={TextField} />
        <Field
          fullWidth
          name="display"
          label="Affichage"
          component={TextField}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="submit"
        >
          {submitText}
          <SendIcon className={classes.rightIcon} />
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          onClick={onCancel}
        >
          Annuler
          <CancelIcon className={classes.rightIcon} />
        </Button>
      </form>
    )}
  />
);

export default withStyles(styles)(FormFeed);
