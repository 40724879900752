import React, { useContext, useEffect, useState } from 'react';
import { Paper, Typography, Button, Select, MenuItem } from '@material-ui/core';

import { baseV2 } from '../../axios';
import { TagsContext } from '../../store/Tags';
import { SnackbarContext } from '../Snackbar';

import { Tag } from './Tag';
import { ADD_NEW_TAG_PLACEHOLDER, getTagClassNameByType } from './helpers';

import { useCustomStyles } from './styles';

export function Tags({ patientTags, onSave, userId }) {
  const cls = useCustomStyles();

  const tags = useContext(TagsContext);
  const { open } = useContext(SnackbarContext);

  const [activeTags, setActiveTags] = useState(patientTags);
  const [availableTags, setAvailableTags] = useState(tags);

  useEffect(() => {
    setActiveTags(patientTags);
  }, [patientTags.length]);

  useEffect(() => {
    const newAvailableTags = tags.filter((tag) => {
      return !activeTags.some(({ id }) => {
        return id === tag.id;
      });
    });
    setAvailableTags(newAvailableTags);
  }, [activeTags]);

  const updateTags = async () => {
    try {
      const { data: newTags } = await baseV2.put(`/patients/${userId}/tags`, {
        tags: activeTags.map(({ id }) => id)
      });
      onSave(newTags);

      open('Tags have been updated', 'success');
    } catch (err) {
      open('Updating tags failed', 'error');
    }
  };

  const handleDelete = (removedItemId) => {
    setActiveTags(activeTags.filter(({ id }) => id !== removedItemId));
  };

  const handleAddNewTag = (event) => {
    const newTag = availableTags.find(({ id }) => id === event.target.value);
    setActiveTags([...activeTags, newTag]);
  };

  return (
    <Paper className={cls.root}>
      <Typography variant="h1" className={cls.header}>
        Manage Tags
      </Typography>
      <div className={cls.tags}>
        {activeTags.map((item) => (
          <Tag
            key={item.id}
            onDelete={() => handleDelete(item.id)}
            data={item}
            className={cls.tag}
          />
        ))}
        {!!availableTags.length && (
          <Select
            onChange={handleAddNewTag}
            value={ADD_NEW_TAG_PLACEHOLDER}
            className={cls.dropdown}
            classes={{ icon: cls.dropdownIcon }}
            MenuProps={{
              classes: {
                paper: cls.dropdownMenu,
                list: cls.dropdownList
              }
            }}
          >
            <MenuItem
              value={ADD_NEW_TAG_PLACEHOLDER}
              className={cls.placeholder}
            >
              Add tag
            </MenuItem>
            {availableTags.map(({ id, type, name }) => (
              <MenuItem
                key={id}
                value={id}
                className={getTagClassNameByType(type, cls)}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <Button variant="contained" color="primary" onClick={updateTags}>
        Save
      </Button>
    </Paper>
  );
}
