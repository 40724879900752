import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import parseISO from 'date-fns/parseISO';
import axios from '../../../../axios';
import { format } from '../../../../format';
import Loadable from '../../../Loadable';
import AddComment from './AddComment';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
});

const Comments = ({ type, id, classes, readOnly }) => {
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);

  const fetchComments = async () => {
    if (displayLoader) {
      setIsFetching(true);
    }
    try {
      const { data: fetchedComments } = await axios.get(
        `${type}/${id}/comments`
      );

      setComments(fetchedComments);
      // do not display loader after first fetch
      setDisplayLoader(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const addComment = async ({ comment }, form) => {
    try {
      await axios.post(`${type}/${id}/comments`, {
        comment
      });
      form.change('comment', '');
      fetchComments();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <Loadable loading={isFetching}>
      <Grid container spacing={2} className={classes.root}>
        {!readOnly && (
          <Grid item xs={12}>
            <AddComment onSubmit={addComment} />
          </Grid>
        )}

        <Grid item xs={12}>
          <List className={classes.list}>
            {comments.map((comment) => (
              <Grid key={comment.id} item xs={12}>
                <ListItem key={comment.id}>
                  <ListItemText
                    primary={comment.content}
                    secondary={`${
                      comment.authorable_type.includes(`Practician`)
                        ? `Dr. ${comment.authorable.user.fullname}`
                        : comment.authorable.user.fullname
                    } - ${format(
                      parseISO(comment.created_at),
                      'dd MMMM HH:mm'
                    )}`}
                  />
                </ListItem>
              </Grid>
            ))}
          </List>
        </Grid>
      </Grid>
    </Loadable>
  );
};

export default withStyles(styles)(Comments);
