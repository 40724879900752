import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { addDays } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { format, formatDistance } from '../../../../format';

const getEnd = (aligners, remainingAligners) => {
  if (remainingAligners.length) {
    return parseISO(remainingAligners.slice(-1).pop().end);
  }

  const lastAligners = aligners.slice(-1).pop();

  if (lastAligners.finished_at) return parseISO(lastAligners.finished_at);

  return addDays(parseISO(lastAligners.started_at), 15);
};

const getFormattedEnd = (aligners, remainingAligners) => {
  const end = getEnd(aligners, remainingAligners);

  if (!end) return '';

  return format(end, 'dd MMMM yyyy');
};

const Informations = ({
  patient: { aligners },
  patient,
  remainingAligners
}) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography color="textSecondary">Début</Typography>
        <Typography gutterBottom>
          {format(parseISO(patient.treatment_started_at), 'dd MMMM yyyy')}
        </Typography>
        <Typography color="textSecondary">Fin</Typography>
        <Typography gutterBottom>
          {getFormattedEnd(aligners, remainingAligners)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color="textSecondary">Durée</Typography>
        <Typography gutterBottom>
          {getEnd(aligners, remainingAligners) &&
            formatDistance(
              getEnd(aligners, remainingAligners),
              parseISO(patient.treatment_started_at)
            )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Informations;
