export const formatComments = (comments) => {
  return comments
    .map((comment) => {
      let author = '';
      if (
        comment.authorable_type.includes('Patient') ||
        comment.authorable_type.includes('EConsult')
      ) {
        author = 'Patient : ';
      } else if (comment.authorable_type.includes('Administrator')) {
        author = 'Joovence : ';
      } else {
        author = 'Doctor : ';
      }
      return `${author}${comment.translated_content}`;
    })
    .join('\n\n');
};
