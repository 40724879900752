import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { v2 } from '../../../axios';
import Loadable from '../../Loadable';
import ExportForm from './ExportForm';
import ExportInformation from './ExportInformation';

const ExportPatient = ({ patientId, onExport }) => {
  const [patient, setPatient] = useState(null);
  const [exportedPatient, setExportedPatient] = useState(null);

  useEffect(() => {
    setPatient(null);
    const fetchPatient = async () => {
      const { data: fetchedPatient } = await v2.get(
        `patients/supplier-export/${patientId}`
      );

      setPatient(fetchedPatient);
    };

    setExportedPatient(null);
    fetchPatient();
  }, [patientId]);

  const onSubmit = async (values) => {
    try {
      const { data } = await v2.post(
        `patients/supplier-export/${patientId}`,
        values
      );
      setExportedPatient(data);
      if (onExport) {
        onExport(patientId);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  if (!patient) return <Loadable loading />;

  return (
    <Box p={2}>
      <Typography variant="h6">{patient.user.fullname}</Typography>
      {exportedPatient ? (
        <ExportInformation information={exportedPatient} />
      ) : (
        <ExportForm onSubmit={onSubmit} patient={patient} />
      )}
    </Box>
  );
};

export default ExportPatient;
