import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  }
});

const SelectPracticians = ({ classes, practicians, onChange }) => {
  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="select-practicians"
        options={practicians}
        getOptionLabel={(practician) =>
          `Dr. ${practician.fullname} - ${practician.alias}`
        }
        filterSelectedOptions
        onChange={onChange}
        renderTags={(value, getTagProps) =>
          value.map((practician, index) => (
            <Chip
              variant="outlined"
              label={practician.fullname}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label="Practicians"
            placeholder="Dr ..."
          />
        )}
      />
    </div>
  );
};
export default withStyles(styles)(SelectPracticians);
