import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { differenceInDays, addDays } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { format } from '../../../../format';

class Aligners extends Component {
  render() {
    const {
      patient: { aligners },
      remainingAligners
    } = this.props;

    const alignersRow = aligners.map((a) => {
      const daysWorn = differenceInDays(
        parseISO(a.finished_at),
        parseISO(a.started_at)
      );
      return (
        <TableRow key={a.id}>
          <TableCell component="th" scope="row">
            <Typography variant="body1">{a.serie}</Typography>
          </TableCell>
          <TableCell>{format(parseISO(a.started_at), 'dd/MM/yyyy')}</TableCell>
          <TableCell>
            {a.finished_at ? (
              <>
                <Typography>{`${format(
                  parseISO(a.finished_at),
                  'dd/MM/yyyy'
                )}`}</Typography>
              </>
            ) : (
              <Typography color="textSecondary">{`${format(
                addDays(parseISO(a.started_at), 15),
                'dd/MM/yyyy'
              )}`}</Typography>
            )}
          </TableCell>
          <TableCell>
            {a.finished_at ? (
              <Typography
                color={daysWorn < 15 || daysWorn > 20 ? 'error' : 'initial'}
              >{`${daysWorn} jours`}</Typography>
            ) : null}
          </TableCell>
          <TableCell>
            <IconButton
              color="primary"
              size="small"
              aria-label="View aligner"
              component={Link}
              to={`/patient/${a.patient_id}/treatment/${a.serie}`}
            >
              <SearchIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });

    const remainingRows = remainingAligners.map((a, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            <Typography color="textSecondary">{a.serie}</Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary">
              {format(parseISO(a.start), 'dd/MM/yyyy')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary">{`${format(
              parseISO(a.end),
              'dd/MM/yyyy'
            )}`}</Typography>
          </TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      );
    });

    const alignersTable = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Serie</TableCell>
            <TableCell>Début</TableCell>
            <TableCell>Fin</TableCell>
            <TableCell>Jours portés</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {alignersRow}
          {remainingRows}
        </TableBody>
      </Table>
    );

    return alignersTable;
  }
}

export default Aligners;
