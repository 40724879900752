import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Form, Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import Select from '../final-form/Select';
import TextField from '../final-form/TextField';

import axios from '../../axios';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
});

const validate = (values) => {
  const errors = {};

  if (!values.practician_id) {
    errors.practician_id = 'Obligatoire';
  }

  if (!values.code) {
    errors.code = 'Obligatoire';
  }

  return errors;
};

class LinkStripe extends Component {
  state = {
    error: null,
    practicians: [],
    success: false,
    isLinking: false
  };

  componentDidMount() {
    this.fetchPracticians();

    const url = new URL(window.location.href);

    if (url.searchParams.get('error')) {
      this.setState({
        error: url.searchParams.get('error_description')
      });
    }
  }

  fetchPracticians = async () => {
    const { data: practicians } = await axios.get('/practicians');

    this.setState({ practicians });
  };

  handleSubmit = async (values) => {
    this.setState({ isLinking: true });

    try {
      const response = await axios.get(`/practician/link-stripe`, {
        params: {
          practician_id: values.practician_id,
          code: values.code
        }
      });

      this.setState({ isLinking: false });
      if (response.data.error) {
        return { code: response.data.error_description };
      }
      this.setState({ success: true });

      return undefined;
    } catch ({ response: { data } }) {
      this.setState({ isLinking: false });
      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  getAlreadyLinked = (practician_id) => {
    const { practicians } = this.state;

    if (!practician_id) {
      return null;
    }

    const practician = practicians.find((p) => p.id === practician_id);

    if (!practician || !practician.stripe_user_id) {
      return null;
    }

    return (
      <Typography gutterBottom color="error">
        Ce praticien a déjà lié son compte Stripe. Pour le remplacer cliquer sur
        lier.
      </Typography>
    );
  };

  render() {
    const { practicians, success, error, isLinking } = this.state;
    const { classes } = this.props;

    if (error) {
      return (
        <Typography gutterBottom color="error">
          {error}
        </Typography>
      );
    }

    if (success) {
      return (
        <Paper className={classes.root}>
          <Typography gutterBottom color="success">
            Link successful
          </Typography>
        </Paper>
      );
    }

    const url = new URL(window.location.href);

    const code = url.searchParams.get('code');

    return (
      <Paper className={classes.root}>
        <Form
          onSubmit={this.handleSubmit}
          validate={validate}
          initialValues={{
            code
          }}
          render={({ handleSubmit, hasValidationErrors, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Field
                    fullWidth
                    disabled
                    name="code"
                    label="Code"
                    component={TextField}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    formControlProps={{ fullWidth: true }}
                    name="practician_id"
                    label="Selectionner un praticien"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    render={(props) => <Select {...props} />}
                  >
                    {practicians.map((p) => (
                      <MenuItem
                        key={p.id}
                        value={p.id}
                      >{`Dr. ${p.user.fullname}`}</MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>

              {this.getAlreadyLinked(values.practician_id)}
              <Button
                variant="contained"
                type="submit"
                disabled={hasValidationErrors || isLinking}
                color="primary"
              >
                Lier
              </Button>
            </form>
          )}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(LinkStripe);
