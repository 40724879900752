import React from 'react';
import { Link } from 'react-router-dom';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

export const PatientFormatter = ({ value, row }) => {
  return <Link to={`/patient/${row.id}/export`}>{value}</Link>;
};

export const PatientTypeProvider = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DataTypeProvider formatterComponent={PatientFormatter} {...props} />
);
