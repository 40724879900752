import React, { Fragment, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';

import {
  Grid as GridDX,
  Table,
  TableHeaderRow,
  Toolbar,
  SearchPanel
} from '@devexpress/dx-react-grid-material-ui';

import { SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';

import { withStyles } from '@material-ui/core/styles';
import { Button, Tab, Tabs } from '@material-ui/core';
import Header from '../../../Header';

import { v2 } from '../../../../axios';

import Loadable from '../../../Loadable';
import { PatientTypeProvider } from './ListPatientFormatter';
import ExportPatient from '../../export/ExportPatient';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  }
});

const ListExport = ({ classes }) => {
  const [patients, setPatients] = useState([]);
  const [status, setStatus] = useState('ready');
  const [activePatient, setActivePatient] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const onStatusChange = (_, newStatus) => {
    setStatus(newStatus);
  };

  useEffect(() => {
    const fetchPatients = async () => {
      setIsFetching(true);

      try {
        const { data } = await v2.get('/patients/supplier-export', {
          params: { status }
        });

        setPatients(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };
    fetchPatients();
  }, [status]);

  const onExport = (patientId) => {
    const patientsWithoutExported = patients.filter((p) => p.id !== patientId);

    setPatients(patientsWithoutExported);
  };

  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Patients export to supplier', url: '/patients/export' }
        ]}
      />

      {activePatient && (
        <Paper className={classes.root}>
          <ExportPatient patientId={activePatient} onExport={onExport} />
        </Paper>
      )}
      <Paper className={classes.root}>
        <Loadable loading={isFetching}>
          <Tabs
            value={status}
            indicatorColor="primary"
            textColor="primary"
            onChange={onStatusChange}
            aria-label="list status"
          >
            <Tab
              label={`Ready${
                status === 'ready' ? ` (${patients.length})` : ''
              }`}
              value="ready"
            />
            <Tab
              label={`Incomplete${
                status === 'incomplete' ? ` (${patients.length})` : ''
              }`}
              value="incomplete"
            />
          </Tabs>
          <GridDX
            rows={patients}
            columns={[
              {
                title: 'Patient',
                name: 'patient',
                getCellValue: (p) => {
                  return `${p.id} - ${p.fullname}`;
                }
              },
              {
                title: 'Validation date',
                name: 'check_up_validated_at',
                getCellValue: (p) => {
                  return p.check_up_validated_at;
                }
              },
              {
                title: 'X-ray',
                name: 'xray',
                getCellValue: (p) => {
                  if (!p.files_xray_count) return '❌';
                  return '✅';
                }
              },
              {
                title: 'Scans',
                name: 'scans',
                getCellValue: (p) => {
                  if (!p.files_scans_count) return '❌';
                  return '✅';
                }
              },
              {
                title: 'Transfered data',
                name: 'status',
                getCellValue: (p) => {
                  if (!p.accepted_allow_transfer_data_at) return '❌';
                  return '✅';
                }
              },
              {
                title: 'Export',
                name: 'export',
                getCellValue: (p) => {
                  return (
                    <Button
                      onClick={() => {
                        setActivePatient(p.id);
                      }}
                    >
                      Export
                    </Button>
                  );
                }
              }
            ]}
          >
            <SearchState defaultValue="" />
            <IntegratedFiltering />
            <Table />
            <TableHeaderRow />
            <Toolbar />
            <SearchPanel />

            <PatientTypeProvider for={['patient']} />
          </GridDX>
        </Loadable>
      </Paper>
    </>
  );
};

export default withStyles(styles)(ListExport);
