export const getAddressPart = (place, part) => {
  const component = place.address_components.find((item) =>
    item.types.includes(part)
  );

  if (!component) return '';

  return component.long_name;
};

export const getCountry = (place) => {
  const countryComponent = place.address_components.find((component) =>
    component.types.includes('country')
  );

  if (!countryComponent) return null;

  return countryComponent.short_name;
};
