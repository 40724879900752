import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Header from '../Header';

import axios, { baseV2 } from '../../axios';
import Informations from './view/Informations';
import Tabs from './view/Tabs';
import Booking from './view/booking/Booking';
import Production from './view/production/Production';
import Treatment from './view/treatment/Treatment';
import Comments from './view/comments/Comments';
import Bookings from './view/bookings/Main';
import ExportPatient from './export/ExportPatient';
import { Tags } from '../Tags/Tags';

const styles = (theme) => ({
  patientWrapper: {
    padding: theme.spacing(),
    paddingBottom: theme.spacing(3)
  }
});

class View extends Component {
  state = {
    patient: null,
    // eslint-disable-next-line react/destructuring-assignment
    tab: this.props.match.params.tab || 'booking',
    tags: []
  };

  fetchPatient = async () => {
    const { match } = this.props;

    try {
      const [{ data: patient }, { data: tags }] = await Promise.all([
        axios.get(`/patient/${match.params.id}`),
        baseV2.get(`/patients/${match.params.id}/tags`)
      ]);
      this.setState({ patient, tags });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  componentDidMount = async () => {
    await this.fetchPatient();
  };

  changeTab = (tab) => {
    this.setState({ tab });
  };

  setTags = (newTags) => {
    this.setState({ tags: newTags });
  };

  loginAs = async () => {
    const { patient } = this.state;
    try {
      const { data: url } = await axios.get(
        `/patient/${patient.user_id}/login-as`
      );
      window.location.replace(url);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  render = () => {
    const { classes, snackbar } = this.props;
    const { patient, tags, tab } = this.state;

    if (!patient) {
      return null;
    }

    const {
      user: { addresses },
      user: infos
    } = patient;

    return (
      <>
        <Header
          breadcrumbs={[
            { name: 'Patients', url: '/patients' },
            {
              name: infos.fullname,
              url: `/patient/${patient.id}`
            }
          ]}
        />

        <Card className={classes.patientWrapper} align="center">
          <CardHeader
            action={
              <>
                <IconButton
                  href={`${process.env.REACT_APP_API_URL}/nova/resources/patients/${patient.id}`}
                  target="_blank"
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton aria-label="add" onClick={this.loginAs}>
                  <ExitToAppIcon />
                </IconButton>
              </>
            }
          />

          <Informations
            patient={patient}
            infos={infos}
            addresses={addresses}
            tags={tags}
          />
          <Tabs
            url={`/patient/${patient.id}`}
            changeTab={this.changeTab}
            value={tab}
          />

          <Switch>
            <Route
              path="/patient/:id/production/:piece?"
              render={(props) => (
                <Production
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  fetchPatient={this.fetchPatient}
                  patient={patient}
                />
              )}
            />
            <Route
              path="/patient/:id/booking/:piece?"
              render={(props) => (
                <Booking
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  fetchPatient={this.fetchPatient}
                  patient={patient}
                />
              )}
            />
            <Route
              path="/patient/:id/treatment/:piece?"
              render={(props) => (
                <Treatment
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  snackbar={snackbar}
                  fetchPatient={this.fetchPatient}
                  patient={patient}
                />
              )}
            />
            <Route
              path="/patient/:id/comments"
              render={(props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Comments {...props} type="patient" id={patient.id} />
              )}
            />
            <Route
              path="/patient/:id/bookings"
              render={(props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Bookings {...props} patient={patient} snackbar={snackbar} />
              )}
            />
            <Route
              path="/patient/:id/export"
              render={() => <ExportPatient patientId={patient.id} />}
            />
            <Route
              path="/patient/:id/tags"
              render={() => (
                <Tags
                  patientTags={tags}
                  onSave={this.setTags}
                  userId={patient.id}
                />
              )}
            />
          </Switch>
        </Card>
      </>
    );
  };
}

export default withStyles(styles)(View);
