export const VERSION_INITIAL = 'initial';
export const VERSION_WITH_ALIGNERS = 'with_aligners';

export const angles = [
  {
    name: 'occlussal_upper',
    display: 'Occlusal upper'
  },
  {
    name: 'occlussal_lower',
    display: 'Occlusal lower'
  },
  {
    name: 'buccal_left',
    display: 'Buccal left'
  },
  {
    name: 'buccal_right',
    display: 'Buccal right'
  },
  {
    name: 'labial_anterior',
    display: 'Labial anterior'
  }
];

export const anglesWithAligners = [
  {
    name: 'buccal_left',
    display: 'Buccal left'
  },
  {
    name: 'buccal_left_aligners',
    display: 'Buccal left aligners'
  },
  {
    name: 'buccal_right',
    display: 'Buccal right'
  },
  {
    name: 'buccal_right_aligners',
    display: 'Buccal right aligners'
  },
  {
    name: 'labial_anterior',
    display: 'Labial anterior'
  },
  {
    name: 'labial_anterior_aligners',
    display: 'Labial anterior aligners'
  }
];

export const getAnglesFromVersion = (version) => {
  if (version === VERSION_WITH_ALIGNERS) return anglesWithAligners;
  return angles;
};
