import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import GetBooking from '../booking/GetBooking';
import ListBookings from './ListBookings';

import axios from '../../../../axios';

class Main extends Component {
  state = {
    bookings: null
  };

  componentDidMount() {
    this.fetchBookings();
  }

  fetchBookings = async () => {
    const { patient } = this.props;
    try {
      const { data: bookings } = await axios.get(
        `/patient/${patient.id}/bookings`
      );

      this.setState({ bookings });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  render() {
    const { patient, snackbar } = this.props;
    const { bookings } = this.state;

    if (!bookings) {
      return null;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <GetBooking
            patient={patient}
            type="pre_treatment"
            onSuccess={this.fetchBookings}
          />

          <ListBookings
            patient={patient}
            bookings={bookings}
            fetchBookings={this.fetchBookings}
            snackbar={snackbar}
          />
        </Grid>
      </Grid>
    );
  }
}

export default Main;
